import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _isArray from 'lodash/isArray';
import './Section.scss';
import { AuthContext, useAuth0Context } from '../../context/auth';
import { useEnvironmentState } from '../../context/environment';
import { getListSection, updateSection } from '../../services/sectionList';
import { convertPubDateForPdf } from '../../utils/convertDate';
import { AxiosResponse } from 'axios';
import {
  ISection,
  ISectionResponse,
} from '../../utils/types/sectionResponseType';
import { ISelect } from '../../utils/types/selectBoxType';
import { initSection, publicationName } from '../../utils/sectionHelper';
import { SectionComponent } from './Section-component';
import { withGuardWrapper } from '../../utils/toggleShowFeatures/wrapperFeatureGuard';

const useStyles = makeStyles(() =>
  createStyles({
    'MuiSelect-select': {
      minWidth: 100,
    },
    button: {
      marginTop: 70,
    },
  })
);

const Section: React.FC = () => {
  const env = useEnvironmentState();
  const { authState }: AuthContext = useAuth0Context();
  const bearerToken: string | null = _get(authState, 'idToken', '');
  const classes = useStyles();

  const [date, setDate] = useState<Date>(new Date());
  const [pubName, setPubName] = useState<string>('PHILADELPHIA INQUIRER');
  const [sections, setSections] = useState<ISection[]>([] as ISection[]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagePlan, setPagePlan] = useState<ISectionResponse>(
    {} as ISectionResponse
  );

  useEffect(() => {
    onGetListSection(new Date(), pubName);
  }, []);

  const onGetListSection = (date: Date, pubName: string): void => {
    if (bearerToken) {
      setIsLoading(true);

      getListSection(
        convertPubDateForPdf(date.toString()),
        pubName,
        bearerToken,
        `${_get(env, 'REACT_APP_API_BASE_URL', '')}/section`
      )
        .then((result: AxiosResponse<ISectionResponse>) => {
          setPagePlan(_get(result, 'data'));

          let currentSections: ISection | ISection[] = _get(
            result,
            'data.page_plan.pub.section',
            []
          );

          if (_isArray(currentSections) === false) {
            currentSections = [currentSections as unknown as ISection];
          }

          setSections(currentSections as ISection[]);
        })
        .catch(() => {
          setPagePlan({
            page_plan: {
              pub: {
                _attributes: {
                  name: pubName,
                  pubdate: convertPubDateForPdf(date.toString()),
                },
                section: [],
              },
            },
          });
          setSections([]);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onUpdateSection = () => {
    const pagePlanTmp: ISectionResponse = { ...pagePlan };
    pagePlanTmp.page_plan.pub.section = sections;

    if (_isEmpty(pagePlan) === false && bearerToken) {
      setIsLoading(true);

      updateSection(
        pagePlanTmp,
        bearerToken,
        `${_get(env, 'REACT_APP_API_BASE_URL', '')}/section`
      )
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onAddNewSection = () => {
    const newSection: ISection = initSection();

    setSections([...sections, newSection]);
  };

  return (
    <div className='container'>
      <Helmet defer={false}>
        <title>Section Manifest - DocCenter 2.0</title>
      </Helmet>
      <div className='section-title'>
        <Grid container spacing={3}>
          <h3>Doccenter Section</h3>
          <Grid container spacing={4} className='search-form'>
            <Grid item>
              <h5>Pub. Name:</h5>
              <Select
                className={classes['MuiSelect-select']}
                variant='outlined'
                value={pubName}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  const pubNameTmp = e.target.value as string;

                  setPubName(pubNameTmp);
                  onGetListSection(date, pubNameTmp);
                }}
              >
                {_map(publicationName(), (selectItem: ISelect, idx: number) => (
                  <MenuItem value={selectItem.value} key={idx}>
                    {selectItem.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <h5>Pub. Date:</h5>
              <LocalizationProvider
                dateLibInstance={moment}
                dateAdapter={MomentUtils}
                locale='en-US'
              >
                <DatePicker
                  value={date}
                  onChange={(date: Moment | null) => {
                    if (date !== null) {
                      setDate(date.toDate());
                      onGetListSection(date.toDate(), pubName);
                    }
                  }}
                  renderInput={(props: any) => (
                    <TextField {...props} variant='outlined' />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                className={classes['button']}
                onClick={() => onGetListSection(date, pubName)}
              >
                Refresh
              </Button>
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='contained'
                className={classes['button']}
                onClick={() => onUpdateSection()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className='section-body'>
        {_isEmpty(sections) === false ? (
          <SectionComponent sections={sections} setSections={setSections} />
        ) : (
          <h4>No data found !</h4>
        )}

        <Button
          color='default'
          variant='contained'
          className={classes['button']}
          onClick={() => onAddNewSection()}
        >
          Add new section
        </Button>
      </div>

      <Dialog open={isLoading} maxWidth={false}>
        <div className='loading-modal'>
          <CircularProgress />
        </div>
      </Dialog>
    </div>
  );
};

export default Section;
