import _map from 'lodash/fp/map';
import _truncate from 'lodash/truncate';
import { DeathNoticeResultProps, PageResultProps, PhotoResultProps, ResultItemType, StoryResultProps } from '../components/Search/Results';
import {ObjectTypes} from '../services/elasticSearch';
import decodeHTML from './decodeHTML';
import env from '../context/environment/initial';
import pathJoin from './pathJoin';
import {convertPubDateOffset} from '../utils/convertDate';
import { handleUrl } from './searchHelper';

export interface ResultType {
  componentName?: ResultItemType,
  objectProp: StoryResultProps | PhotoResultProps | PageResultProps | DeathNoticeResultProps | null,
}

export type ElasticSearchResponse = {
  ID: number,
  OBJ_TYPE: ObjectTypes,
  PAGE_ALPHA: string,
  PUB_DATE: string,
  PUBLICATION: string,
  EDITION: string,
  PUBLISHED_CAPTION: string,
  TEXT: string,
  H_PATH: string,
  L_PATH: string,
  SHOOT_DATE: string,
  PHOTOGRAPHER: string,
  BYLINE: string,
  SECTION: string,
  LENGTH: number,
  HEADLINE: string,
  SOURCE_CAPTION: string,
  RECORD_SECURITY: number,
  PUB_LINK: string,
};

export type LightboxElasticSearchResponse = {
  body: string,
  credit: string,
  city: string,
  state: string,
  source: string,
  filename: string,
  locationPath: string,
  objectId: number,
  shootDate: string,
  updateDate: string,
  id: number,
};

export const transformLightboxResults = _map<LightboxElasticSearchResponse, ResultType>((_source: LightboxElasticSearchResponse) => {
  const thumnailUrl = `${env.REACT_APP_S3_LIGHTBOX}/InqData/Store${_source.locationPath}/${_source.objectId}_low.jpg`;
  return {
    componentName: 'photo',
    objectProp: {
      id: _source.objectId,
      photographer: _source.credit,
      publishedCaption: _source.body,
      shootDate: _source.shootDate,
      pubDate: _source.updateDate,
      thumbnail: thumnailUrl,
    },
  };
});

export const transformResults = _map<ElasticSearchResponse, ResultType>((_source: ElasticSearchResponse) => {
  const {
    ID,
    OBJ_TYPE,
    PAGE_ALPHA,
    PUB_DATE,
    PUBLICATION,
    EDITION,
    PUBLISHED_CAPTION: _PUBLISHED_CAPTION,
    TEXT: _TEXT,
    H_PATH,
    HEADLINE: _HEADLINE,
    SOURCE_CAPTION: _SOURCE_CAPTION,
    RECORD_SECURITY,
    PUB_LINK,
  } = _source;
  const SOURCE_CAPTION = decodeHTML(_SOURCE_CAPTION);
  const PUBLISHED_CAPTION = decodeHTML(_PUBLISHED_CAPTION);
  const TEXT = decodeHTML(_TEXT);
  const HEADLINE = decodeHTML(_HEADLINE);

  switch (OBJ_TYPE) {
    case ObjectTypes.PHOTO:
      const {L_PATH, SHOOT_DATE, PHOTOGRAPHER} = _source;
      const photo: PhotoResultProps = {
        id: ID,
        source: H_PATH,
        thumbnail: `${env.REACT_APP_S3_IMAGE_SERVER}/${handleUrl(H_PATH)}`,
        photographer: PHOTOGRAPHER,
        publishedCaption: PUBLISHED_CAPTION,
        sourceCaption: SOURCE_CAPTION,
        shootDate: SHOOT_DATE,
        pubDate: PUB_DATE,
        status: RECORD_SECURITY,
        slug: PUB_LINK,
      };
      return {
        componentName: 'photo',
        objectProp: photo,
      };
    case ObjectTypes.TEXT:
      const {
        BYLINE,
        SECTION,
        LENGTH,
      } = _source;
      const story: StoryResultProps = {
        id: ID,
        header: HEADLINE,
        date: convertPubDateOffset(PUB_DATE),
        author: decodeHTML(BYLINE).replace(/\+/g, ''),
        section: SECTION,
        page: PAGE_ALPHA,
        edition: EDITION,
        publisher: PUBLICATION,
        wordCount: LENGTH,
        body: TEXT,
        status: RECORD_SECURITY,
        slug: PUB_LINK,
      };
      return {
        componentName: 'story',
        objectProp: story,
      };
    case ObjectTypes.DEATH_NOTICE:
      const deathNotice: DeathNoticeResultProps = {
        id: ID,
        header: _truncate(HEADLINE),
        date: convertPubDateOffset(PUB_DATE),
        body: _truncate(TEXT, {
          length: 300,
        }),
      };
      return {
        componentName: 'death_notice',
        objectProp: deathNotice,
      };
    case ObjectTypes.PDF:
      const page: PageResultProps = {
        id: ID,
        source: pathJoin([H_PATH, `${PAGE_ALPHA}.pdf`]),
        date: convertPubDateOffset(PUB_DATE),
        page: PAGE_ALPHA,
        edition: EDITION,
        publisher: PUBLICATION,
      };
      return {
        componentName: 'page',
        objectProp: page,
      };
    default:
      return {objectProp: null};
  }
});

export default transformResults;
