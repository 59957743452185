import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { getListPdf } from '../../services/pdfList';
import { convertPubDateForPdf } from '../../utils/convertDate';
import {
  IListPdfRepsonse,
  IPdfObject,
} from '../../utils/types/pdfResponseType';
import { AuthContext, useAuth0Context } from '../../context/auth';
import { AxiosResponse } from 'axios';
import { useEnvironmentState } from '../../context/environment';
import Skeleton from '@material-ui/lab/Skeleton';
import { PdfFolder } from './PdfFolder';
import './ListPdf.scss';
import { withGuardWrapper } from '../../utils/toggleShowFeatures/wrapperFeatureGuard';

const ListPdf: React.FC = () => {
  const { authState }: AuthContext = useAuth0Context();
  const env = useEnvironmentState();
  const bearerToken: string | null = _get(authState, 'idToken', '');
  const [date, setDate] = useState<Date>(new Date());
  const [expanded, setExpanded] = useState<boolean[]>([true,true]);
  const [listPdf, setListPdf] = useState<IPdfObject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onGetListPdf(new Date());
  }, []);

  const sortTheList = (list: IPdfObject[]) => {
    return list.sort((a, b) => {
      if (isNaN(parseInt(a.name[0])) === false) { // first character is number
        return parseInt(a.name.split('.')[0]) - parseInt(b.name.split('.')[0]);
      }

      const numA = parseInt(a.name.slice(1));
      const numB = parseInt(b.name.slice(1));

      // Compare the numeric parts
      return numA - numB;
    });
  }

  const onGetListPdf = (date: Date): void => {
    if (bearerToken) {
      setIsLoading(true);

      getListPdf(
        convertPubDateForPdf(date.toString()),
        bearerToken,
        _get(env, 'REACT_APP_PDF_LIST_ENDPOINT', '')
      )
        .then((result: AxiosResponse<IListPdfRepsonse>) => {
          const prettiersList: IPdfObject[] = _get(
            result,
            'data.data.prettier',
            []
          );

          processList(prettiersList);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const processList = (pdfList: IPdfObject[]) => {
    let newArrayPages: Array<IPdfObject> = [];
    console.log(pdfList);
    for(let i=0; i<pdfList.length; i++) {
      const pdfItem = pdfList[i];
      let newChild = createGrid(pdfItem.child);
      newArrayPages.push({
        name: pdfItem.name,
        child: newChild,
      });
    }
    setListPdf(newArrayPages);
  }

  // const addEmptiedPages = (child: IPdfObject[], path: string): JSX.Element => {
  const createGrid = (child: IPdfObject[]): IPdfObject[] => {
    let newChild: Array<IPdfObject> = [];
    
    for(let i=0; i<child.length; i++) {
      const pdfItem = child[i];

      if (pdfItem.name === 'section.xml') continue;
      
      if (pdfItem.child.length>0) {
        const matches = pdfItem.child[pdfItem.child.length-1].name.match(/(\d+)/g);
        if (matches) {
          newChild.push({
            name: pdfItem.name,
            child: addEmptiedPages(pdfItem.child),
          });
        } else {
          newChild.push({
            name: pdfItem.name,
            child: createGrid(pdfItem.child),
          });
        }
      } else {
        newChild.push({
          name: pdfItem.name,
          child: pdfItem.child,
        });
      }
    }
    return (newChild);
  }

  const addEmptiedPages = (sectionPages: IPdfObject[]): IPdfObject[] => {
    sectionPages = sortTheList(sectionPages);
    let newArrayPages: Array<IPdfObject> = [];

    let matches = sectionPages[sectionPages.length-1].name.match(/\d+/g);
    if (matches) {
      let maxPageNumber = parseInt(matches[0]);
      
      for (let i=0; i<maxPageNumber; i++) {
        const emptiedPage = {name:'...',child:[]} as IPdfObject;
        newArrayPages.push(emptiedPage);
      }
      
      for (let i=0; i<sectionPages.length; i++) {
        let matches = sectionPages[i].name.match(/\d+/g);
        if (matches && parseInt(matches[0])>0) {
          let pageNumber = parseInt(matches[0]);
            newArrayPages[pageNumber-1].name = sectionPages[i].name;
          }
        }
      }
    return(newArrayPages);
  }

  const handleClickCollapsePublication = (index: number) => {
    const tmpCollapPublication: boolean[] = [...expanded];
    tmpCollapPublication[index] = !expanded[index];

    setExpanded(tmpCollapPublication);
  };

  const renderPublication = (): JSX.Element => {
    if (_isEmpty(listPdf) === false) {
      return (
        <>
          {_map(listPdf, (publicationCollapse: IPdfObject, idx: number) => (
            <Card className='advanced-search' key={idx}>
              <CardActionArea
                onClick={() => handleClickCollapsePublication(idx)}
              >
                <CardContent className='card-header'>
                  <ExpandMoreIcon
                    className={`expand-icon ${
                      expanded[idx] === true ? 'expand-open' : 'expand-close'
                    }`}
                  />
                  <Typography component='b'>
                    {_get(publicationCollapse, 'name', 'Not Found Data')}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Collapse
                in={expanded[idx] === true}
                timeout='auto'
                unmountOnExit
              >
                <PdfFolder
                  child={_get(publicationCollapse, 'child', [])}
                  path={_get(publicationCollapse, 'name', '')}
                  date={date}
                />
              </Collapse>
            </Card>
          ))}
        </>
      );
    }

    return (
      <>
        <Card className='advanced-search'>
          <CardActionArea>
            <CardContent className='card-header'>
              <Typography component='b'>No data found for this date</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  };

  return (
    <div className='container'>
      <Helmet defer={false}>
        <title>ShowPDF - DocCenter 2.0</title>
      </Helmet>
      <div className='pdf-title'>
        <Grid container>
          <Grid container className='search-form'>
            <Grid item>
              <div className='pub-date-picker'>
                <LocalizationProvider
                  dateLibInstance={moment}
                  dateAdapter={MomentUtils}
                  locale='en-US'
                >
                  <DatePicker
                    
                    label='Published Date'
                    value={date}
                    onChange={(date: Moment | null) => {
                      if (date !== null) {
                        setDate(date.toDate());
                        onGetListPdf(date.toDate());
                      }
                    }}
                    renderInput={(props: any) => (
                      <TextField {...props} variant='outlined' />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className='pdf-body'>
        {isLoading ? (
          <Skeleton variant='text' height={150} />
        ) : (
          renderPublication()
        )}
      </div>
    </div>
  );
};

export default withGuardWrapper({
  key: 'REACT_APP_HIDE_PDF'
})(ListPdf);
