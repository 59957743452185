import _get from 'lodash/get';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0Context } from '../context/auth';

const useIsAuthenticated = () => useMemo(() => {
  const issuedOn = +`${localStorage.getItem('issuedOn')}`;
  const expiresAt = +`${localStorage.getItem('expiresAt')}`;
  const expiresIn = expiresAt * 1000 + issuedOn;
  const loggedIn = localStorage.getItem('isLoggedIn');
  return loggedIn === 'true' && new Date().getTime() < expiresIn;
}, []);

export const useAuth0 = () => {
  const { auth0, authState, updateAuthState } = useAuth0Context();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const login = useCallback(() => {
    const prompt = localStorage.getItem('loggedOut') === 'true' ? 'login' : undefined;

    auth0?.authorize({
      prompt,
      state: window.location.pathname + window.location.search,
    });
  }, []);

  const logout = useCallback(() => {
    updateAuthState({
      accessToken: null,
      idToken: null,
      expiresAt: 0
    });
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loggedOut', 'true');

    auth0?.logout({
      returnTo: window.location.origin
    });

    // TODO: Redirect to homepage
  }, [auth0, updateAuthState]);

  const setSession = useCallback(
    (issuedOn, authResult) => {
      localStorage.removeItem('loggedOut');
      localStorage.setItem('isLoggedIn', 'true');

      const expiresAt = authResult.expiresIn * 1000 + issuedOn;
      const accessToken = authResult.accessToken;
      const idToken = authResult.idToken;
      const roles = _get(authResult.idTokenPayload, 'https://inquirer.com/roles', '');
      const email = _get(authResult.idTokenPayload, 'email');
      const name = _get(authResult.idTokenPayload, 'name');
      const picture = _get(authResult.idTokenPayload, 'picture');

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('expiresAt', `${authResult.expiresIn}`);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('roles', roles);
      localStorage.setItem('email', email);
      localStorage.setItem('name', name);
      localStorage.setItem('picture', picture);

      updateAuthState({
        accessToken,
        idToken,
        expiresAt,
        roles: roles.split('|'),
        email,
        name,
        picture,
      });
    },
    []
  );

  const renewSession = useCallback(() => {
    if (isAuthenticated) {
      const issuedOn = +`${localStorage.getItem('issuedOn')}`;
      setSession(issuedOn, {
        accessToken: localStorage.getItem('accessToken'),
        idToken: localStorage.getItem('idToken'),
        expiresIn: +`${localStorage.getItem('expiresAt')}`,
        idTokenPayload: {
          'https://inquirer.com/roles': localStorage.getItem('roles'),
          email: localStorage.getItem('email'),
          name: localStorage.getItem('name'),
          picture: localStorage.getItem('picture'),
        },
      });
    } else {
      login();
    }
  }, []);

  const handleAuthentication = useCallback(() => {
    auth0?.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const issuedOn = new Date().getTime();
        localStorage.setItem('issuedOn', `${issuedOn}`);
        setSession(issuedOn, authResult);
        history.push(authResult.state || '/');
      } else if (err) {
        // TODO: Redirect to home
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }, []);

  return {
    login,
    logout,
    handleAuthentication,
    isAuthenticated,
    renewSession
  };
};