import React, {ReactNode} from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';

type UrlResolver = () => string | undefined;

export interface HeaderLinkProps {
  url: string | UrlResolver,
  label?: string,
  children?: ReactNode,
  external?: boolean,
  isDisplay?: boolean
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  url,
  label,
  external,
  isDisplay = true,
  ...props
}) => {
  const location = useLocation();
  const to = typeof url === 'string' ? url : (url as UrlResolver)() || '';

  const opts = external ? {
    to: {
      pathname: to,
    },
    target: "_blank",
  } : {
    to,
  };

  return isDisplay ? (
    <div className={`header-hover-effect ${location.pathname === url ? 'selected' : ''}`}>
      <Link
        style={{ textDecoration: 'none' }}
        {...opts}
      >
        <div className='header-menu-button'>
          {label || props.children}
        </div>
      </Link>
    </div>
  ) : <></>;
};

export default HeaderLink;