import { ISelect } from './types/selectBoxType';
import _startsWith from 'lodash/startsWith';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import { LightBoxSource, Source } from '../pages/details/DetailsPage';
import { convertPubDateOffset } from './convertDate';
import { DetailsMetaInfoProps } from '../components/Details/DetailsMetaInfo';
import { translateState } from '../services/elasticSearch';

export enum ArticleStatusConst {
  AUTO_ARCHIVED = '2',
  ARCHIVED = '3',
  NEEDS_REVIEW = '7',
  KILLED = '9',
}

export const articleStatus = (): ISelect[] => {
  return [
    {
      label: 'Archived',
      value: ArticleStatusConst.ARCHIVED,
    },
    {
      label: 'Auto-Archived',
      value: ArticleStatusConst.AUTO_ARCHIVED,
    },
    {
      label: 'Needs Review',
      value: ArticleStatusConst.NEEDS_REVIEW,
    },
    {
      label: 'Killed',
      value: ArticleStatusConst.KILLED,
    },
  ];
};

export const handleUrl = (url: string | undefined): string | undefined => {
  if (url && _startsWith(url, '/') === true) {
    return url.substring(1);
  }

  return url;
};

export const differenceSource = (
  currentObject: Source,
  newObject: Source
): Source => {
  if (_isEqual(currentObject, newObject) === true) {
    return {};
  }

  const keys: string[] = _keys(newObject);
  let diff = {} as Source;

  _forEach(keys, (key: string) => {
    if (_get(newObject, key) !== _get(currentObject, key) && typeof _get(newObject, key) !== 'undefined') {
      diff = {
        ...diff,
        [key]: _get(newObject, key)
      }
    }
  })

  return diff;
};

export const transformMetaInfo = (source: Source, id: string): DetailsMetaInfoProps => ({
  publication: source.PUBLICATION,
  date: `${source.DAY}, ${convertPubDateOffset(source.PUB_DATE || '')}`,
  edition: source.EDITION,
  page: source.PAGE_ALPHA,
  id: source.ID || id,
  section: source.SECTION,
  words: source.LENGTH,
  graphics: source.GRAPHICS,
  status: translateState(source.RECORD_SECURITY),
});

const characterEntities = [
  ['', /\+\n/g],
  ['&', /&#38;/g],
  ['>', /&#62;/g],
  ['<', /&#60;/g],
  ['"', /&#34;/g],
  ["'", /&#39;/g],
];

export const decodeHTMLForEditField = (text: string = '') => {
  characterEntities.forEach(([entity, encodedCharacter]) => {
    text = text.replace(encodedCharacter as RegExp, entity as string);
  });
  return text;
};

export default decodeHTMLForEditField;
