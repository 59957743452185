import React from 'react';
import _map from 'lodash/map';
import _truncate from 'lodash/truncate';
import './Highlighter.scss';

const applyHighlighter = (
  content: string,
  searchIndices: number[],
  searchPhraseLength: number,
) => {
  const renderedText: JSX.Element[] = [];
  let outsideSearch = 0;

  for (let i=0; i<searchIndices.length; i++) {
    const idx = searchIndices[i]
    renderedText.push(
      <>
        {content.substr(outsideSearch, idx)}
        <mark>{content.substr(idx, searchPhraseLength)}</mark>
      </>
    );
    outsideSearch = idx + searchPhraseLength;
  }
  
  renderedText.push(<>{content.substr(outsideSearch)}</>);

  return renderedText;
};

const trimContent = (
  content: string,
  references: number[],
  phraseLen: number,
  shortenLength: number = 300,
) => {
  if (references.length === 0) {
    return _truncate(content, {
      length: shortenLength,
    });
  }

  if (content.length <= shortenLength) {
    return content;
  }

  const first = references[0],
        second = references.length > 1 ?
                  references[references.length-1] :
                  content.length;
  const maxDistance = second-first;
  
  if (maxDistance > shortenLength) {
    return `...${content.substr(first, first+shortenLength)}...`;
  }

  const endPhrase = content.substr(first, second+phraseLen);
  return `...${content.substr(endPhrase.length-shortenLength)}${endPhrase}`;
};

export interface HighlighterProps {
  searchPhrase: string,
  shortenLength?: boolean,
}

export const Highlighter: React.FC<HighlighterProps> = ({
  searchPhrase,
  ...props
}) => {
  const searchTerms = (searchPhrase.match(/\w+/gi) || [])
    .filter(term => !['AND', 'OR', 'NOT'].includes(term));
  const searchRegex = new RegExp(`\\b(${searchTerms.join('|')})\\b`, 'gi');

  if (props.children && typeof props.children === 'string') {
    const __html = props.children.replace(searchRegex, '<mark>$1</mark>')
      .replace(/\<\/mark\>\s*\<mark\>/gi, ' ');

    return (
      <span dangerouslySetInnerHTML={{__html}} />
    );
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default Highlighter;