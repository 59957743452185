import React from 'react';
import './Homepage.scss';
import {Helmet} from "react-helmet";
import SearchForm from '../../components/Search/SearchForm';

export interface HomepageProps {

}

export const Homepage: React.FC<HomepageProps> = ({
  ...props
}) => {
  return (
    <div className="homepage">
      <Helmet>
        <title>DocCenter 2.0</title>
      </Helmet>
      <SearchForm layout="main"/>
    </div>
  );
};
