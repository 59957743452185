import React, { ChangeEvent } from 'react';
import './DetailsMetaInfo.scss';
import { createStyles, Grid, makeStyles, TextField, Chip } from '@material-ui/core';
import _get from 'lodash/get';
import Skeleton from '@material-ui/lab/Skeleton';
import { Source } from '../../pages/details/DetailsPage';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';

export interface DetailsMetaInfoProps extends DetailsMetaInfoBehaviorProps {
  publication?: string;
  date?: string;
  edition?: string;
  page?: string;
  id?: string;
  section?: string;
  words?: string;
  graphics?: string;
  status?: string | null;
  isEdit?: boolean;
  handleOnSourceChanges?: (key: string, value: string) => void;
  cloneSource?: Source;
}

export interface DetailsMetaInfoBehaviorProps {
  loading?: boolean;
}

interface FieldContainerProps extends DetailsMetaInfoBehaviorProps {
  label: string;
  value?: string | string[];
  multiple?: boolean,
  isEditField?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  isFullSize?: boolean;
  handleOnSourceChanges?: (key: string, value: string) => void;
  fieldName?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    'badge-label': {
      marginRight: 5,
      marginBottom: 5,
    },
    'fields-container': {
      marginTop: 10,
      verticalAlign: 'baseline',
    },
    'full-size': {
      width: '100%',
    },
    'normal-size': {},
  })
);

export const FieldContainer: React.FC<FieldContainerProps> = ({
  label,
  value,
  loading,
  multiple,
  isEditField = false,
  variant = undefined,
  isFullSize = false,
  handleOnSourceChanges = undefined,
  fieldName = '',
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes['fields-container']}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {loading ? <Skeleton width={50}/> : <b>{label}</b>}
        </Grid>
        <Grid item xs={8}>
          {loading ? (
            <Skeleton />
          ) : isEditField ? (
            <TextField
              value={value}
              variant={variant}
              className={classes[isFullSize ? 'full-size' : 'normal-size']}
              multiline={isFullSize}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
                typeof handleOnSourceChanges !== 'undefined' &&
                handleOnSourceChanges(fieldName, event.target.value)
              }
            />
          ) : (
            multiple ? (value as string[]).map(val => <Chip className={classes['badge-label']} label={val}/>) : value
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DetailsMetaInfo: React.FC<DetailsMetaInfoProps> = ({
  publication,
  date,
  edition,
  page,
  id,
  section,
  words,
  graphics,
  loading,
  status,
  isEdit = false,
  handleOnSourceChanges = undefined,
  cloneSource = {},
}) => {
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  return (
    <div className='details-meta-info'>
      <Grid container spacing={1}>
        {editPermitted && status && (
          <FieldContainer
            label="Status"
            value={status}
            loading={loading}
          />
        )}
        <FieldContainer
          label='Publication'
          value={publication}
          loading={loading}
        />
        <FieldContainer label='Date' value={date} loading={loading} />
        {edition && (
          <FieldContainer
            label='Edition'
            value={isEdit ?  _get(cloneSource, 'EDITION', '') : edition}
            loading={loading}
            isEditField={isEdit}
            variant='standard'
            isFullSize={true}
            handleOnSourceChanges={handleOnSourceChanges}
            fieldName='EDITION'
          />
        )}
        {page && (
          <FieldContainer
            label='Page'
            value={isEdit ? _get(cloneSource, 'PAGE_ALPHA', '') : page}
            loading={loading}
            isEditField={isEdit}
            variant='standard'
            isFullSize={true}
            fieldName='PAGE_ALPHA'
            handleOnSourceChanges={handleOnSourceChanges}
          />
        )}
        <FieldContainer label='ID' value={id} loading={loading} />
        {section && (
          <FieldContainer
            label='Section'
            value={isEdit ? _get(cloneSource, 'SECTION', '') : section}
            loading={loading}
            isEditField={isEdit}
            variant='standard'
            isFullSize={true}
            fieldName='SECTION'
            handleOnSourceChanges={handleOnSourceChanges}
          />
        )}
        {words && (
          <FieldContainer label='Words' value={words} loading={loading} />
        )}
        {graphics && (
          <FieldContainer multiple label='Graphics' value={graphics.split(' AND ')} loading={loading} />
        )}
      </Grid>
    </div>
  );
};

export default DetailsMetaInfo;
