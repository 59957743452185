import axios, { AxiosResponse } from 'axios';
import env from '../context/environment/initial';
import { convertPubDate } from '../utils/convertDate';
import { IListPdfRepsonse } from '../utils/types/pdfResponseType';

const esEndpoint = `${env.REACT_APP_ELASTIC_SEARCH_ENDPOINT}/_search`;

export const getListPdf = (date: string, bearerToken: string, url: string): Promise<AxiosResponse<IListPdfRepsonse>> =>
  axios.get(`${url}/${date}`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });

export const getPagesBySection = (
  date: string,
  publication: string,
  edition: string,
  section: string | null,
  bearerToken: string
) => (
  axios.post(esEndpoint, {
    track_total_hits: true,
    size: 100,
    query: {
      bool: {
        must: [
          {
            term: {
              PUB_DATE: convertPubDate(date),
            },
          },
          {
            exists: {
              field: 'PAGE_ALPHA.keyword',
            },
          },
          ...([
            ['PUBLICATION', publication],
            ['EDITION', edition],
            ['SECTION', section],
          ].map(([field, value]) => value && {
            term: {
              [`${field}.keyword`]: value,
            }
          }).filter(Boolean)),
        ]
      }
    }
  }, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    }
  })
);