/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isDisplayFeature } from './checkIsDisplayKey';

interface IProps {}

interface IOptionProps {
  key: string;
}

export const withGuardWrapper = ({ key }: IOptionProps) => <
  WrapperProps extends IProps
>(
  WrappedComponent: React.FC<WrapperProps>
) => {
  const FeatureGuardWrapper: React.FC<Omit<IProps, 'guard'>> = (
    props: React.PropsWithChildren<Pick<IProps, never>>
  ) => {
    if (isDisplayFeature(key) === false) {
      return <></>;
    }

    return <WrappedComponent {...(props as WrapperProps)} />;
  };

  return FeatureGuardWrapper;
};
