import React, {ReactNode, useState} from 'react';
import {HeaderLinkProps} from './HeaderLink';
import { NavLink } from 'react-router-dom';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import './Header.scss';

export interface HeaderProps {
  label: string,
  children?: ReactNode,
}

export const Header: React.FC<HeaderProps> = ({
  label,
  children,
  ...props
}) => {
  const [menuItem, selectMenuItem] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    selectMenuItem(newValue);
  };

  return (
    <header>
      <div className="main-header">
        <div className="header-logo header-left">
          <NavLink exact 
            style={{ textDecoration: 'none' }}
            to="/" 
          >
            <div className='header-menu-button'>
              <FindInPageIcon/>{label}
            </div>
          </NavLink>
        </div>
        <div className="header-right">
          {React.Children.map(children, child => {
            if (React.isValidElement<HeaderLinkProps>(child)) {
              return child;
            }
          })}
        </div>
      </div>
    </header>
  );
};

export default Header;
