import React, { ChangeEvent, useEffect, useState } from 'react';
import _get from 'lodash/get';
import _times from 'lodash/times';
import './StoryDetails.scss';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import ArchiveIcon from '@material-ui/icons/Archive';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DetailsMetaInfo, {
  DetailsMetaInfoProps,
} from '../../components/Details/DetailsMetaInfo';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import ShareBox from '../ShareBox/ShareBox';
import decodeHTML from '../../utils/decodeHTML';
import PhotoCaptionsBox from '../PhotoCaptionsBox/PhotoCaptionsBox';
import splitPublishedCaptions from '../../utils/splitPublishedCaptions';
import { Source } from '../../pages/details/DetailsPage';
import decodeHTMLForEditField from '../../utils/searchHelper';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';
import { Graphics, Publications } from '../../services/documentSearch';
export interface StoryDetailsProps extends DetailsBehaviorProps {
  title: string;
  byline?: string;
  content: string;
  metaInfo: DetailsMetaInfoProps;
  captions?: string;
  cloneSource: Source;
  disableUpdateButton?: boolean;
  isSubmitting?: boolean
  handleChangeStatus: (status: number) => void;
  onClickSubmitUpdateDocument: (clonedSource: Source) => void;
  handleOnSourceChanges: (key: string, value: string) => void;
}

export interface DetailsBehaviorProps {
  loading?: boolean;
}

type Variant = 'h4' | 'h6' | 'body1';

const publications = [
  { publication: 'PHILADELPHIA INQUIRER' },
  { publication: 'PHILADELPHIA DAILY NEWS' },
] ;

const graphics = [
  { graphic: 'PHOTO' },
  { graphic: 'GRAPHIC' },
  { graphic: 'CARTOON' },
  { graphic: 'DRAWING' },
  { graphic: 'CHART' },
  { graphic: 'MAP' },
  { graphic: 'DIAGRAM' },
] ;

export interface PortionProps {
  variant?: Variant;
  component?: string;
}

export const Portion: React.FC<PortionProps> = ({
  children,
  variant,
  ...props
}) => {
  return (
    <div className='details-portion'>
      <Grid item>
        <Typography {...props} variant={variant}>
          {children}
        </Typography>
      </Grid>
    </div>
  );
};

export interface FormattedContentProps {
  content: string;
}

export const FormattedContent: React.FC<FormattedContentProps> = ({
  content,
}) => {
  const paragraphs = decodeHTML(content).split('\n');
  return (
    <>
      {paragraphs.map((p) => (
        <p>{p}</p>
      ))}
    </>
  );
};

export interface DisplayFieldProps {
  label: string;
  value?: string;
  hidden?: boolean;
  loading?: boolean;
}

export const DisplayField: React.FC<DisplayFieldProps> = ({
  label,
  value,
  hidden,
  loading
}) => {
  if (loading) {
    return (
      <Portion>
        <Typography display="block"><Skeleton width={200} variant='text' /></Typography>
        <Typography><Skeleton width={400} variant='text' /></Typography>
      </Portion>
    );
  }

  if (!value || hidden) {
    return <></>;
  }

  return (
    <div className="display-field">
      <Portion>
        <Typography display="block"><strong>{label}</strong></Typography>
        <Typography><FormattedContent content={value}/></Typography>
      </Portion>
    </div>
  );
};

export const StoryDetails: React.FC<StoryDetailsProps> = ({
  title,
  captions,
  byline,
  content,
  metaInfo,
  loading,
  cloneSource,
  disableUpdateButton,
  isSubmitting,
  handleChangeStatus,
  handleOnSourceChanges,
  onClickSubmitUpdateDocument,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [actionInUse, setActionInUse] = useState('');
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  useEffect(() => {
    if (isEdit && isSubmitting === false) {
      setIsEdit(!disableUpdateButton);
    }
  }, [isSubmitting]);

  const _renderStoryContent = (): JSX.Element => (
    <>
      <Portion variant='h4'>
        {loading ? (
          <Typography variant='body1'>
            <Skeleton width={500} variant='text' />
          </Typography>
        ) : (
          <div className="header-title">
            {decodeHTML(title)
              .split('\n')
              .filter(Boolean)
              .map(title => <div>{title}</div>)}
          </div>
        )}
      </Portion>
      {loading ? (
        <Typography>
          <Skeleton width={400} variant='text' />
        </Typography>
      ) : byline && (
        <Portion>
          <b>
           {loading ? (
              <Typography>
                <Skeleton width={300} variant='text' />
              </Typography>
            ) : (
              <div className="header-title">
                {decodeHTML(byline)
                  .split('\n')
                  .filter(Boolean)
                  .map(byline => <div>{byline}</div>)}
              </div>
            )}
          </b>
        </Portion>
      )}
      <DisplayField
        label="Dateline"
        value={cloneSource.DATELINE}
        loading={loading}
      />
      <Portion variant='body1'>
        {loading ? (
          <>
            {_times(7, () => <p>
              {_times(4, () => <Skeleton height={20} variant='text' />)}
              <Skeleton width={300} height={20} variant='text' />
            </p>)}
          </>
        ) : (
          <FormattedContent content={content}/>
        )}
      </Portion>
      <DisplayField
        label="Lead Graphs"
        value={cloneSource.LEAD_GRAPH}
        hidden={!editPermitted}
        loading={loading}
      />
      <DisplayField
        label="Keywords"
        value={cloneSource.TEXT_KEYWORDS}
        loading={loading}
      />
      <DisplayField
        label="Memo"
        value={cloneSource.MEMO}
        loading={loading}
      />
      <DisplayField
        label="Published Correction"
        value={cloneSource.PUBLISHED_CORRECTION}
        loading={loading}
      />
      <DisplayField
        label="Unpublished Correction"
        value={cloneSource.UNPUBLISHED_CORRECTIONS}
        loading={loading}
      />
      <DisplayField
        label="Slug"
        value={cloneSource.PUB_LINK}
        loading={loading}
      />
      <DisplayField
        label="Section Letter"
        value={cloneSource.PAGE_TYPE}
        hidden={!editPermitted}
        loading={loading}
      />
      <DisplayField
        label="PDF Path"
        value={cloneSource.PDF_PATH}
        hidden={!editPermitted}
        loading={loading}
      />
    </>
  );

  const _renderEditStoryContent = (): JSX.Element => (
    <>
      <TextField
        multiline
        label='Headline'
        defaultValue={_get(cloneSource, 'HEADLINE', '')}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('HEADLINE', event.target.value)}
      />
      <TextField
        multiline
        label='Byline'
        defaultValue={_get(cloneSource, 'BYLINE', '')}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('BYLINE', event.target.value)}
      />
      <TextField
        label='Dateline'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'DATELINE', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('DATELINE', event.target.value)}
      />
      <Autocomplete
        multiple
        options={Object.keys(Graphics)}
        defaultValue={_get(cloneSource, 'GRAPHICS', '').split(' AND ').filter(key => Object.keys(Graphics).includes(key))}
        filterSelectedOptions
        disableCloseOnSelect
        onChange={(
          event: ChangeEvent<{}>,
          value: string[],
        ) => {handleOnSourceChanges('GRAPHICS', value.join(' AND '))}}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className='title-text-field'
            label="Graphics"
          />
        )}
      />
      <TextField
        label='Section'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'SECTION', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('SECTION', event.target.value)}
      />
      <TextField
        multiline
        label='Lead Graph'
        defaultValue={_get(cloneSource, 'LEAD_GRAPH', '')}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('LEAD_GRAPH', event.target.value)}
      />
      <TextField
        multiline
        label='Text'
        defaultValue={_get(cloneSource, 'TEXT', '')}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('TEXT', event.target.value)}
      />
      <Autocomplete
        disableClearable
        options={Object.keys(Publications).map((k: string) => Publications[k as keyof typeof Publications])}
        defaultValue={_get(cloneSource, 'PUBLICATION', '')}
        onChange={(
          event: ChangeEvent<{}>,
          value: string | null,
        ) => handleOnSourceChanges('PUBLICATION', value || '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pub"
            className='title-text-field'
            variant="outlined"
          />
        )}
      />
      <TextField
        label='Date'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PUB_DATE', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PUB_DATE', event.target.value)}
      />
      <TextField
        label='Edition'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'EDITION', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('EDITION', event.target.value)}
      />
      <TextField
        label='Page'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PAGE_ALPHA', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PAGE_ALPHA', event.target.value)}
      />
      <TextField
        multiline
        label='Pub Caption'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PUBLISHED_CAPTION', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PUBLISHED_CAPTION', event.target.value)}
      />
      <TextField
        label='Keywords'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'TEXT_KEYWORDS', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('TEXT_KEYWORDS', event.target.value)}
      />
      <TextField
        multiline
        label='Memo'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'MEMO', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('MEMO', event.target.value)}
      />
      <TextField
        multiline
        label='Pub Correct'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PUBLISHED_CORRECTION', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PUBLISHED_CORRECTION', event.target.value)}
      />
      <TextField
        multiline
        label='Unpub Correct'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'UNPUBLISHED_CORRECTIONS', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('UNPUBLISHED_CORRECTIONS', event.target.value)}
      />
      <TextField
        label='Slug'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PUB_LINK', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PUB_LINK', event.target.value)}
      />
      <TextField
        label='Page Type'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PAGE_TYPE', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PAGE_TYPE', event.target.value)}
      />
      <TextField
        label='PDF Path'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'PDF_PATH', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('PDF_PATH', event.target.value)}
      />
      <TextField
        label='Day'
        defaultValue={decodeHTMLForEditField(_get(cloneSource, 'DAY', ''))}
        className='title-text-field'
        variant='outlined'
        size='medium'
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('DAY', event.target.value)}
      />
    </>
  );

  return (
    <div className='story-details'>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Grid container direction='column'>
            {isEdit ? _renderEditStoryContent() : _renderStoryContent()}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {(loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted &&
                (isEdit === false ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        startIcon={<EditIcon />}
                        onClick={() => setIsEdit(!isEdit)}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        startIcon={<TransitEnterexitIcon />}
                        onClick={() => setIsEdit(!isEdit)}
                      >
                        Exit (No Save)
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={disableUpdateButton || isSubmitting}
                        startIcon={isSubmitting ? <CircularProgress size={20}/> : <PublishIcon />}
                        onClick={() => {
                          onClickSubmitUpdateDocument(cloneSource);
                        }}
                      >
                        Save &amp; Close
                      </Button>
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
            <Grid item xs={12}>
              {(loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted && !isEdit &&
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      startIcon={isSubmitting && actionInUse === 'archive' ? <CircularProgress size={20}/> : <ArchiveIcon />}
                      disabled={isSubmitting}
                      onClick={() => {
                        setActionInUse('archive');
                        handleChangeStatus(3);
                      }}
                    >
                      Archive
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      startIcon={isSubmitting && actionInUse === 'kill' ? <CircularProgress size={20}/> : <ClearIcon />}
                      disabled={isSubmitting}
                      onClick={() => {
                        setActionInUse('kill');
                        handleChangeStatus(9);
                      }}
                    >
                      Kill
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <DetailsMetaInfo
                loading={loading}
                {...metaInfo}
                isEdit={isEdit}
                handleOnSourceChanges={handleOnSourceChanges}
                cloneSource={cloneSource}
              />
            </Grid>
            <Grid item xs={12}>
              <ShareBox loading={loading}/>
            </Grid>
            <Grid item xs={12}>
              <PhotoCaptionsBox items={splitPublishedCaptions(captions)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(StoryDetails);
