import React from 'react';
import './PhotoCaptionsBox.scss';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import decodeHTML from '../../utils/decodeHTML';

export interface PhotoCaptionsBoxProps {
  items: string[]
}

export const PhotoCaptionsBox: React.FC<PhotoCaptionsBoxProps> = ({
  items,
  ...props
}) => {
  return (
    <div className="photo-captions-box">
      {items.length > 0 && <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">Published Photo Captions</Typography>
        </Grid>
        <Divider />
        {items.map(item => (
          <Grid item>
            <Grid container>
              <Grid item xs={1}>
                <PhotoSizeSelectActualOutlinedIcon className="item-icon" />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="caption">{decodeHTML(item)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>}
    </div>
  );
};

export default PhotoCaptionsBox;
