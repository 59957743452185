import {
  createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core';
import React from 'react';
import {
  FieldContainer
} from '../../components/Details/DetailsMetaInfo';
import './PhotoDetails.scss';

export interface LightBoxDetailProps extends DetailsBehaviorProps {
  cdId: number;
  objectId: number;
  size?: number;
  title: string;
  source: string;
  shoot_date: string;
  photographer: string;
  file_name: string;
  photo_path?: string;
}

export interface DetailsBehaviorProps {
  loading?: boolean;
}

type Variant = 'h4' | 'h6' | 'body1';

export interface PortionProps {
  variant?: Variant;
  component?: string;
}

export const Portion: React.FC<PortionProps> = ({ children, ...props }) => {
  return (
    <div className='details-portion'>
      <Grid item>
        <Typography {...props}>{children}</Typography>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    'note-text': {
      fontStyle: 'italic',
    },
  })
);

export const LightBoxDetails: React.FC<LightBoxDetailProps> = ({
  cdId,
  objectId,
  title,
  photo_path,
  source,
  shoot_date,
  photographer,
  file_name,
  loading,
  ...props
}) => {
  const classes = useStyles();

  const _renderTitle = (): JSX.Element => (
    <Portion variant='h4'>
      {title}
    </Portion>
  );

  return (
    <div className='photo-details'>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Grid container justify='center'>
            {_renderTitle()}
            <img
              width='100%'
              height='100%'
              src={photo_path}
              alt='main_photo'
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify='center' className='details-meta-info-sticky' spacing={3}>
            <Grid item xs={12}>
              <div className='details-meta-info'>
                <Grid container spacing={1}>
                  <FieldContainer
                    label='CD ID'
                    value={cdId?.toString()}
                    loading={loading}
                  />
                  <FieldContainer
                    label='Object ID'
                    value={objectId?.toString()}
                    loading={loading}
                  />
                  <FieldContainer
                    label='File Name'
                    value={file_name}
                    loading={loading}
                  />
                  <FieldContainer
                    label='Credit'
                    value={photographer}
                    loading={loading}
                  />
                  <FieldContainer
                    label='Shoot Date'
                    value={new Date(shoot_date).toLocaleDateString()}
                    loading={loading}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes['note-text']}>
                Please Slack the CD ID and Object ID to
                <Link href='https://app.slack.com/client/T02D86MAA/C02K34AEV'> #photo </Link>
                to get a high resolutions copy of the image.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LightBoxDetails;
