import React, {useEffect, useState} from 'react';
import _times from 'lodash/times';
import _set from 'lodash/set';
import TextField from '@material-ui/core/TextField';
import { DatePicker as _DatePicker, MobileDateRangePicker, DateRangeDelimiter, DateRange, LocalizationProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import './DatePicker.scss';
import { DateRangeFormatter } from '../../utils/searchFilterTools';
import { convertPubDate } from '../../utils/convertDate';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';

export interface SingleYearPickerProps {
  onYearChange?: (n: number) => void,
  value?: number,
}

export interface DatePickerProps extends RangedDatePickerProps {
  range?: boolean,
  yearOnly?: boolean,
}

export interface RangedDatePickerProps {
  defaultDates?: Date[],
  onChange?: () => void,
}

export const SingleDatePicker: React.FC<{}> = ({
  ...props
}) => {
  const [date, setDate] = useState();

  return (
    <_DatePicker
      value={date}
      onChange={(newValue: any) => setDate(newValue)}
      renderInput={(props: any) => <TextField {...props} />}
    />
  );
};

export const SingleYearPicker: React.ForwardRefRenderFunction<HTMLInputElement, SingleYearPickerProps> = ({
  onYearChange,
  ...props
}, ref) => {
  const [year, setYear] = useState<number | ''>('');
  const [error, setError] = useState(false);

  const validateYear = (event: any) => {
    const value = event.target.value;
    if (value >= 1950 && value <= 2050) {
      setError(false);
      setYear(value);
      onYearChange && onYearChange(value);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (ref) {
      _set(ref, 'current.reset', () => setYear(''));
    }
  }, [ref]);

  return (
    <Select
      ref={ref}
      error={error}
      placeholder="YYYY"
      value={year}
      onChange={validateYear}
      variant="outlined"
      className="specific-year-input"
    >
      <MenuItem value="unknown"></MenuItem>
      {_times(50, n => new Date().getFullYear() - n)
        .map((year) => (
          <MenuItem key={`year-${year}`} value={year}>{year}</MenuItem>
        ))}
    </Select>
  );
};

export const SingleYearPickerWithRef = React.forwardRef(SingleYearPicker);

export const RangedDatePicker: React.ForwardRefRenderFunction<HTMLInputElement, RangedDatePickerProps> = ({
  defaultDates,
  onChange,
  ...props
}, ref) => {
  const [date1, date2] = defaultDates || [null, null];
  const [date, setDate] = useState<DateRangeFormatter>([date1 || null, date2 || null]);

  return (
    <>
      <MobileDateRangePicker
        disableFuture
        startText=""
        endText=""
        cancelText=""
        value={[date[0], date[1]]}
        onChange={(newValue: any) => {
          setDate(newValue);
        }}
        onClose={onChange}
        renderInput={(startProps, endProps) => <>
          <TextField {...startProps} helperText=""/>
            <DateRangeDelimiter> TO </DateRangeDelimiter>
          <TextField {...endProps} helperText="" />
        </>}
      />
      <input value={date
        .filter(Boolean)
        .map(d => d && convertPubDate(d.toISOString()))
        .join()} 
        ref={ref} 
        hidden
      />
    </>
  )
}

export const DatePicker: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = ({
  range,
  yearOnly,
  defaultDates,
  onChange,
  ...props
}, ref) => {
  const RangedDatePickerWithRef = React.forwardRef(RangedDatePicker);

  return (
    <div className="date-picker-content">
      <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils} locale="en-US">
        {range ? <RangedDatePickerWithRef ref={ref} defaultDates={defaultDates} onChange={onChange}/> : (yearOnly?  <SingleYearPicker/> : <SingleDatePicker/>)}
      </LocalizationProvider>
    </div>
  );
};

export default React.forwardRef(DatePicker);
