import { ISection } from "./types/sectionResponseType";
import { ISelect } from "./types/selectBoxType";

export const sectionArr = (): ISelect[] => {
  let sections = [] as ISelect[];

  for (let i = 65; i <= 90; i++) {
    const charCode = String.fromCharCode(i);
    sections.push({
      label: charCode,
      value: charCode
    })
  }

  return sections;
}

export const startEndArr = (): ISelect[] => {
  let startEnd = [] as ISelect[];

  for (let i = 1; i <= 254; i++) {
    startEnd.push({
      label: i.toString(),
      value: i.toString()
    })
  }

  return startEnd;
}

export const publicationName = (): ISelect[] => {
  return [
    {
      label: 'PHILADELPHIA DAILY NEWS',
      value: 'PHILADELPHIA DAILY NEWS'
    },
    {
      label: 'PHILADELPHIA INQUIRER',
      value: 'PHILADELPHIA INQUIRER'
    }
  ]
}

export const initSection = (): ISection => ({
  _attributes: {
    name: ''
  },
  letter: {
    _text: 'A'
  },
  start: {
    _text: '1'
  },
  end: {
    _text: '2'
  }
})
