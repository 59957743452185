export enum BlockDatesValues {
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  LAST_12_MONTHS = 'last_12_months',
}

const LAST_7_DAYS = BlockDatesValues.LAST_7_DAYS;
const LAST_30_DAYS = BlockDatesValues.LAST_30_DAYS;
const LAST_12_MONTHS = BlockDatesValues.LAST_12_MONTHS;

export const BlockDates = Object.freeze({
  LAST_7_DAYS, LAST_30_DAYS, LAST_12_MONTHS,
});

export class DateRangeFormatter extends Array<Date | null> {
  public toString(): string {
    return `${this[0]} to ${this[1]}`;
  }
}
