import { ThumborUrlBuilder } from 'thumbor-url-builder-ts';
import { useEnvironmentState } from '../context/environment';

const REGEX_URL_PROTOCOL = new RegExp('^(http[s]?://www\\.|http[s]?://|www\\.)', 'gi');

let thumbor: ThumborUrlBuilder;

export const useResizer = (src: string): string => {
  const env = useEnvironmentState();

  if (!thumbor) {
    const resizerKey = env.REACT_APP_RESIZER_KEY || '';
    const resizerUrl = env.REACT_APP_RESIZER_URL || '';

    thumbor = new ThumborUrlBuilder(resizerKey, resizerUrl);
  }

  const thumborUrl = thumbor
      .setImagePath(src
        .replace(REGEX_URL_PROTOCOL, '')
        .replace(/\s+/g, '%20')
        .replace('?', '%3F')
      )
      .resize(140, 140)
      .smartCrop(true)
      .buildUrl();

  return thumborUrl;
};

export default useResizer;
