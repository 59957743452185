import env from '../context/environment/initial';
import axios from 'axios';

export interface SendToVendorOptions {
  publisher: string;
  publishedDate: string;
  section: string;
}

const esSendToVendorEndpoint = `${env.REACT_APP_ELASTIC_SEARCH_ENDPOINT}/send-to-vendor`;

export const sendToVendor = (options: SendToVendorOptions, token?: string) => (
  axios.post(
    esSendToVendorEndpoint,
    options,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    },
  )
);

export default sendToVendor;
