import React, {useState, useCallback, useContext, useEffect} from 'react';
import './Summary.scss';
import { Button, Typography } from '@material-ui/core';
import arrayToSentence from '../../utils/arrayToSentence';
import SearchForm from './SearchForm';
import { SearchQueryContext } from '../../context/searchQuery';
import convertDate from '../../utils/convertDate';
import Collapse from '@material-ui/core/Collapse/Collapse';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

export interface SummaryProps {
  searchKeyword: string,
  filterKeywords: string,
  dates: string,
  byline: string,
  publication: string,
  caption: string,
  onToggleRefineSearch?: () => void,
}

export class CombinedWords extends Array<string> {
  public toString = (): string => {
    return this.join(', ').replace(/,(?!.*,)/gmi, ' and');
  }
}

const formatResultTypes = (resultType: string) => {
  switch (resultType) {
    case 'story': return 'Story';
    case 'photo': return 'Photo';
    case 'page': return 'Page';
    case 'death_notice': return 'Death Notice';
    default: return '';
  }
};

const formatPublication = (publication: string) => {
  switch (publication) {
    case 'inquirer_and_daily_news': return 'Inquirer & Daily News';
    case 'inquirer': return 'Inquirer';
    case 'daily_news': return 'Daily News';
    default: return '';
  }
};

const formatDateGrammar = (betweenDates: string) => {
  const [date1, date2] = (decodeURIComponent(betweenDates)).split(',');
  const formattedDate1 = convertDate(date1+ 'T05:00:00.000Z');
  const formattedDate2 = convertDate(date2+ 'T05:00:00.000Z');

  if (!date2) {
    return <>published on <strong>{formattedDate1}</strong></>;
  }

  return <>published between <strong>{formattedDate1}</strong> to <strong>{formattedDate2}</strong></>;
};

export const Summary: React.FC<SummaryProps> = ({
  searchKeyword,
  filterKeywords,
  dates,
  byline,
  publication,
  caption,
  onToggleRefineSearch,
  ...props
}) => {
  const [showRefineSearch, toggleRefineSearch] = useState(false);
  const {rerender} = useContext(SearchQueryContext);

  const handleRefineSearch = useCallback(() => {
    toggleRefineSearch(true);
    onToggleRefineSearch && onToggleRefineSearch();
  }, []);

  const RenderFilterKeywords = () => {
    const keywords = (filterKeywords||'').split(',').map(formatResultTypes);
    return <>{filterKeywords && `${arrayToSentence(keywords)}`}</>;
  };

  const RenderDates = () => (
    <>{dates && formatDateGrammar(dates)}</>
  );

  const RenderByline = () => (
    <>{byline && <>byline or photographer: <strong>{byline}</strong></>}</>
  );

  const RenderPublication = () => (
    <>{publication && <>publication: <strong>{formatPublication(publication)}</strong></>}</>
  );

  const RenderCaptions = () => (
    <>{caption && <>caption: <strong>{caption}</strong></>}</>
  );

  const RenderConjunction = (component: any[]) => {
    if (component.length < 2) {
      return component;
    }
    if (component.length === 2) {
      return [component[0], ' and', component[1]];
    }
    let finalComponent: any[] = [];
    component.slice(0, -1).forEach(c => {
      c && finalComponent.push(...[c, ',']);
    });
    console.log([...finalComponent, ' and', ...component.slice(-1)])
    return [...finalComponent, ' and', ...component.slice(-1)];
  };

  useEffect(() => {
    toggleRefineSearch(false);
  }, [rerender]);

  const constructedPhrase = [
    <>Showing {filterKeywords && <strong><RenderFilterKeywords/></strong>} search results {searchKeyword && <>for <strong>{searchKeyword}</strong></>}</>,
    dates && <> <RenderDates/></>,
    (byline || publication || caption) && ' with',
    RenderConjunction([
      byline && <> <RenderByline/></>,
      caption && <> <RenderCaptions/></>,
      publication && <> <RenderPublication/></>,
    ].filter(Boolean)),
  ];

  const matchMin1050 = useMediaQuery('(min-width:1050px)');

  return (
    <div className="search-summary">
      <Collapse in={showRefineSearch} collapsedHeight={matchMin1050 ? 50 : 100} timeout={500}>
        {showRefineSearch && <SearchForm layout="refine" />}
        {!showRefineSearch && <div className="refine-search-box">
          <div className="header-left action-left">
            <Typography>
              {constructedPhrase}
            </Typography>
          </div>
          <div className='header-right action-right action-content'>
            <Button 
              color="primary" 
              variant="contained"
              className="search-button"
              onClick={handleRefineSearch}
            >
              Refine Search
            </Button>
            <Button className="new-search-button" href="/">
              <Typography>New Search</Typography>
            </Button>
          </div>
        </div>}
      </Collapse>
    </div>
  );
};

export default Summary;
