import {useLocation} from "react-router-dom";
import querystring, { ParsedUrlQuery } from 'querystring';
import _mapValues from 'lodash/mapValues';
import _pickBy from 'lodash/pickBy';

export const useQueryString = () => {
  return querystring.parse(useLocation().search.substr(1));
};

export const getQueryString = () => {
  return querystring.parse(window.location.search.substr(1));
};

export const querify = (qs: any) => {
  return querystring.stringify(_pickBy(qs, Boolean))
    .replace(/%2C/g,",");
};

export default useQueryString;