import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import publicationRadioItems, { PublicationSelectionType } from '../../globals/components/publicationRadioItems';
import './AdvancedSearch.scss';
import { ISelect } from '../../utils/types/selectBoxType';
import { FormControlLabel, Radio } from '@material-ui/core';
import { articleStatus } from '../../utils/searchHelper';
import { Auth0Context } from '../../context/auth';

export interface AdvancedSearchProps {
  collapsed?: boolean;
  byline?: string;
  captions?: string;
  publication?: string;
  status?: string;
  lightboxEnabled?: boolean;
}

export type AdvancedSearchState = {
  expanded: boolean;
  publication?: string;
  byline?: string;
  captions?: string;
  status?: string;
  lightboxEnabled?: boolean;
  lightboxDateSearchType?: string;
};

export const lightboxAdvancedSearchFields: Array<{label: string, value: string, ref: React.RefObject<HTMLInputElement>}> = [
  {label: 'Caption', value: 'body'},
  {label: 'Credit', value: 'credit'},
  {label: 'City', value: 'city'},
  {label: 'State', value: 'state'},
  {label: 'Source', value: 'source'},
  {label: 'Filename', value: 'filename'},
  // {label: 'Cd ID', value: 'id'},
  // {label: 'Assignment ID', value: 'assignId'}
].map(field => ({...field, ref: React.createRef()}));

export class AdvancedSearch extends React.Component<
  AdvancedSearchProps,
  AdvancedSearchState
> {
  static contextType = Auth0Context;

  private bylineInputRef: React.RefObject<HTMLInputElement>;
  private captionsInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: AdvancedSearchProps) {
    super(props);
    this.bylineInputRef = React.createRef();
    this.captionsInputRef = React.createRef();
    this.state = {
      expanded: false,
      byline: props.byline,
      captions: props.captions,
      publication: props.publication,
      status: props.status,
      lightboxDateSearchType: undefined,
    };
  }

  getByline() {
    return this.bylineInputRef.current?.value;
  }

  getCaptions() {
    return this.captionsInputRef.current?.value;
  }

  getPublication() {
    return this.state.publication;
  }

  getStatus() {
    return this.state.status;
  }

  getLightboxSearchQuery() {
    return lightboxAdvancedSearchFields.map(({value, ref}) => ({
      [value]: ref.current?.value,
    })).reduce((acc, field) => ({...acc, ...field}), {searchBy: this.state.lightboxDateSearchType});
  }

  createField(label: string, ref: React.RefObject<HTMLInputElement>) {
    return <FormControl>
      <FormLabel className='advanced-search-label'>{label}</FormLabel>
      <TextField
        className='advanced-search-field'
        variant='outlined'
        style={{ width: 300 }}
        inputRef={ref}/>
    </FormControl>;
  }

  LightboxAdvancedSearch(): React.ReactNode {
    return <FormGroup>
      {lightboxAdvancedSearchFields.map(({label, value, ref}) => this.createField(label, ref))}
      <FormControl>
        <FormLabel className='advanced-search-label'>
          Search by
        </FormLabel>
        <RadioGroup
          name='lightboxDateSearchType'
          onChange={(event) => {
            this.setState({ lightboxDateSearchType: event.target.value });
          } }
          value={this.state.lightboxDateSearchType}
          defaultValue={'any'}
        >
          <FormControlLabel
            value='pubDate'
            control={<Radio size='small' />}
            label={'Published Date'}
          />
          <FormControlLabel
            value='shootDate'
            control={<Radio size='small' />}
            label={'Shoot Date'}
          />
        </RadioGroup>
      </FormControl>
    </FormGroup>;
  }

  RegularAdvancedSearch(): React.ReactNode {
    return <FormGroup>
      <FormControl>
        <FormLabel className='advanced-search-label'>
          Byline or photographer
        </FormLabel>
        <TextField
          id='byline-input'
          inputRef={this.bylineInputRef}
          defaultValue={this.state.byline}
          variant='outlined'
          className='advanced-search-field'
          style={{ width: 300 }} />
      </FormControl>
      <FormControl>
        <FormLabel className='advanced-search-label'>
          Captions
        </FormLabel>
        <TextField
          id='captions-input'
          inputRef={this.captionsInputRef}
          defaultValue={this.state.captions}
          variant='outlined'
          className='advanced-search-field'
          style={{ width: 300 }} />
      </FormControl>
      <FormControl>
        <FormLabel className='advanced-search-label'>
          Publication
        </FormLabel>
        <RadioGroup
          name='publication'
          onChange={(event) => {
            this.setState({ publication: event.target.value });
          } }
          defaultValue={PublicationSelectionType.INQUIRER_AND_DAILY_NEWS}
          value={this.state.publication}
        >
          {publicationRadioItems}
        </RadioGroup>
      </FormControl>
      {this.context.authState?.roles?.includes('doccenter-editor') &&
        <FormControl>
          <FormLabel className='advanced-search-label'>
            Status
          </FormLabel>
          <RadioGroup
            name='status'
            onChange={(event) => {
              this.setState({ status: event.target.value });
            } }
            value={this.state.status}
            defaultValue={'any'}
          >
            <>
              {articleStatus().map((select: ISelect) => (
                <FormControlLabel
                  value={select.value}
                  control={<Radio size='small' />}
                  label={select.label} />
              ))}
              <FormControlLabel
                value='any'
                control={<Radio size='small' />}
                label='Any Status' />
            </>
          </RadioGroup>
        </FormControl>}
    </FormGroup>;
  }

  render() {
    return (
      <Card className='advanced-search'>
        <CardActionArea
          onClick={() => {
            this.setState({ expanded: !this.state.expanded });
          }}
          aria-expanded={this.state.expanded}
        >
          <CardContent className='card-header'>
            <ExpandMoreIcon
              className={`expand-icon ${
                this.state.expanded ? 'expand-open' : 'expand-close'
              }`}
            />
            <Typography component='b'>Advanced Search</Typography>
          </CardContent>
        </CardActionArea>
        <Collapse in={this.state.expanded} timeout='auto'>
          <CardContent className='advanced-search-collapsed-content'>
            {this.props.lightboxEnabled ? this.LightboxAdvancedSearch() : this.RegularAdvancedSearch()}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default AdvancedSearch;
