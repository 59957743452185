import axios from 'axios';
import { lightboxAdvancedSearchFields } from '../components/Search/AdvancedSearch';
import env from '../context/environment/initial';
import { GetDocumentOptions } from './documentSearch';

const lightboxEndpoint = `${env.REACT_APP_LIGHTBOX_ENDPOINT}`;

type SearchSizeInfo = {
  from: number,
  size: number,
};

type SearchDates = {
  fromDate: string,
  toDate: string,
}

export const lightboxSearch = (terms: Array<any>,
  bearerToken: string,
  { from, size }: SearchSizeInfo,
  betweenDate?: SearchDates) => axios.post(`${lightboxEndpoint}/_lbSearch`, {
    track_total_hits: true,
    from,
    size,
    query: {
      bool: {
        must: {
          query_string: {
            query: terms.join(' '),
            fields: lightboxAdvancedSearchFields.map(({ value }) => value),
          },
        },
        ...betweenDate && {
          filter: [{
            range: {
              shootDate: {
                gte: betweenDate.fromDate,
                lte: betweenDate.toDate
              }
            }
          }]
        }
      },
    },
  }, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    }
  });

export const getLightbox = <T>(id: string, {
  bearerToken,
}: GetDocumentOptions) => axios.get<T>(
  `${lightboxEndpoint}/lightbox/${id}`,
  {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  },
)

export default lightboxSearch;
