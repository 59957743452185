import React, {createRef, useState, useContext} from 'react';
import './QueryDebug.scss';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { construct, filterObjectTypes } from '../../services/elasticSearch';
import { getResultsQueryString } from '../../utils/useResultsQueryString';
import { SearchQueryContext } from '../../context/searchQuery';
import { useHistory } from 'react-router-dom';

export interface QueryDebugProps {
  onClose: () => void,
}

export const QueryDebug: React.FC<QueryDebugProps> = ({
  onClose,
  ...props
}) => {
  const {data, updateQuery} = useContext(SearchQueryContext);
  const history = useHistory();
  const {q, ...searchOptions} = getResultsQueryString();
  const finalSearchOptions = {
    ...searchOptions,
    resultTypes: searchOptions?.resultTypes?.split(',').map(filterObjectTypes),
    betweenDates: searchOptions?.betweenDates?.split(','),
  };
  const defaultValue = JSON.stringify(construct(q, finalSearchOptions), null, 2);
  const [textValue, setTextValue] = useState(data.query || defaultValue);
  const jsonInputRef = createRef<HTMLInputElement>();
  const formatJson = () => {
    const value = JSON.stringify(JSON.parse(textValue), null, 2);
    setTextValue(value);
  };
  const performQuery = () => {
    updateQuery(jsonInputRef.current?.value || '');
    history.push('/search?custom');
    onClose();
  };

  return (
    <div className="query-mode-paper">
      <Typography>
        Modify your ElasticSearch query to update the result list manually.
      </Typography>
      <div className="query-mode-content">
        <Link href="#" className="content-action" onClick={formatJson}>Beautify</Link>
        <TextField
          className="query-mode-area"
          multiline
          rows={20}
          defaultValue={defaultValue}
          value={textValue}
          onChange={evt => setTextValue(evt.target.value)}
          variant="filled"
          inputRef={jsonInputRef}
          inputProps={{ spellCheck: 'false' }}
        />
      </div>
      <div className="modal-actions">
        <Button 
          variant="contained"
          className="cancel-button modal-button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button 
          color="primary" 
          variant="contained"
          className="ok-button modal-button"
          onClick={performQuery}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default QueryDebug;
