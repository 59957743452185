import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from "react-helmet";
import _get from 'lodash/fp/get';
import _map from 'lodash/fp/map';
import _filter from 'lodash/fp/filter';
import _flow from 'lodash/fp/flow';
import _truncate from 'lodash/truncate';
import Results, { DeathNoticeResult, DeathNoticeResultProps, LoadingSkeleton, PageResult, PageResultProps, PhotoResult, PhotoResultProps, StoryResult, StoryResultProps } from '../../components/Search/Results';
import Summary from '../../components/Search/Summary';
import Pagination from '../../components/Pagination/Pagination';
import { useAuth0Context } from '../../context/auth';
import search, { manualSearch, filterObjectTypes } from '../../services/elasticSearch';
import useQueryString, { querify } from '../../utils/useQueryString';
import './ResultsPage.scss';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { PaginationActionType, usePagination } from '../../utils/usePagination';
import { SearchQueryContext } from '../../context/searchQuery';
import transformResults, { ResultType, transformLightboxResults } from '../../utils/transformSearchResults';
import handleLoadingStateChange from '../../utils/handleLoadingStateChange';
import useResultsQueryString, { getResultsQueryString } from '../../utils/useResultsQueryString';
import usePrevious from '../../utils/usePrevious';
import useUpdateEffect from '../../utils/useUpdateEffect';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';
import WarningIcon from '@material-ui/icons/Warning';
import { Typography } from '@material-ui/core';
import { lightboxSearch } from '../../services/lightboxSearch';

export interface ResultsPageProps {

}

export const ResultsPage: React.FC<ResultsPageProps> = ({
  ...props
}) => {
  const {
    q,
    resultTypes,
    betweenDates,
    prevDates,
    year,
    byline,
    captions,
    publication,
    status,
    sortBy,
  } = useResultsQueryString();

  let [list, setList] = useState<ResultType[]>([{ objectProp: null }]);
  const env = useEnvironmentState();
  const hasEditorRole = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = usePagination();
  const history = useHistory();
  const qs = useQueryString();
  const { data, rerender } = useContext(SearchQueryContext);
  const { authState } = useAuth0Context();
  const bearerToken = authState?.idToken;
  const listenSortByChange = usePrevious(sortBy);
  const [scrollY, setScrollY] = useState(0);
  const [lexicalError, setLexicalError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [syntaxError, setSyntaxError] = useState(false);
  const usingLightbox = resultTypes === 'lightbox';

  const RESULT_LENGTH = hasEditorRole ? 300 : 25;

  const performSearch = () => {
    const { start } = getResultsQueryString();
    return search(q, {
      resultTypes: resultTypes?.split(',').map(filterObjectTypes),
      betweenDates: betweenDates?.split(','),
      prevDates: prevDates,
      year: year,
      byline: byline,
      captions: captions,
      publication: publication,
      status,
      sortBy,
      from: (+start) || 0,
      viewRestrictedResults: hasEditorRole,
      size: RESULT_LENGTH,
    }, bearerToken || '');
  };

  const performLightboxSearch = () => {
    const { q, start, addDetails, shooter, location, id, assignId, betweenDates } = getResultsQueryString();
    const from = (+start) || 0;
    const size = RESULT_LENGTH;
    const dates = (betweenDates || '').split(',');
    if (dates && dates.length === 2) {
      return lightboxSearch([q, addDetails, shooter, location, id, assignId], bearerToken || '', { from, size }, { fromDate: dates[0], toDate: dates[1] });
    }

    return lightboxSearch([q, addDetails, shooter, location, id, assignId], bearerToken || '', { from, size });
  };

  const setStartSearch = (start: number): void => {
    const newQs = querify({
      ...qs,
      start,
    });
    history.push(`/search?${newQs}`);
  };

  const useMoreResults = () => {
    setScrollY(window.scrollY);
    list.push({ objectProp: null });
    const { start } = getResultsQueryString();
    const _from = (+start || 0) + RESULT_LENGTH;
    setStartSearch(_from);
    autoPopulate();
  };

  const populateList = (result: any) => _flow(
    _get('data.hits.hits'),
    (hits) => {
      const ids = hits.map(((item: any) => item._id));
      localStorage.setItem('result_stack', ids.join());
      return hits;
    },
    (hits) => _map((hit) => ({ ...hit._source, ID: hit._id }), hits),
    usingLightbox ? transformLightboxResults : transformResults,
    results => {
      list.pop();
      list.push(...results);
      return list;
    },
    () => {
      setPage(list.length);
      return list;
    }
  )(result);

  const setTotalResults = (value: number) => dispatch({
    type: PaginationActionType.SET_TOTAL_HITS,
    value,
  });

  const setPage = (value: number) => dispatch({
    type: PaginationActionType.SET_PAGE,
    value,
  });

  const autoPopulate = () => handleLoadingStateChange(setIsLoading, async () => {
    const result = await (usingLightbox ? performLightboxSearch() : performSearch());
    populateList(result);
    setTotalResults(result.data?.hits?.total?.value || result.data?.hits?.total);
  }, async (err) => {
    const errorData = err.response?.data;
    const errorStack = _flow(
      _get('error.error.root_cause'),
      _filter(({ type }) => type !== 'query_shard_exception'),
      _get('[0].reason'),
    )(errorData);

    if (errorStack?.match('Lexical error')) {
      setLexicalError(true);
      setDisplayError(true);
    } else if (errorStack?.match('parse_exception')) {
      setSyntaxError(true);
      setDisplayError(true);
    }
  });

  const manualPopulate = () => handleLoadingStateChange(setIsLoading, async () => {
    const result = await manualSearch(data?.query, bearerToken || '');
    populateList(result);
    setTotalResults(result.data.hits.total.value || result.data.hits.total);
  });

  useEffect(() => {
    if (data.query !== '') manualPopulate();
  }, [data]);

  useUpdateEffect(() => {
    if (listenSortByChange !== sortBy) {
      setStartSearch(0);
      setScrollY(0);
      list = [{ objectProp: null }];
      setList(list);
      window.scrollTo(0, 0);
    }
    autoPopulate();
  }, [sortBy]);

  useEffect(() => {
    window.scrollTo(0, scrollY);
  }, [isLoading]);

  useEffect(() => {
    autoPopulate();
  }, []);

  return (
    <div className="results-page">
      <Helmet>
        <title>DocCenter 2.0</title>
      </Helmet>
      <div className="search-information-background">
        <div className="search-information-content">
          <Summary key='summary'
            searchKeyword={q}
            filterKeywords={resultTypes}
            dates={betweenDates}
            byline={byline}
            publication={publication}
            caption={captions}
            onToggleRefineSearch={() => setDisplayError(false)}
          />
        </div>
      </div>
      {!(lexicalError || syntaxError) &&
        <>
          <div className="search-result-pagination">
            <Pagination loading={isLoading} />
          </div>
          <div className="search-result-list">
            <Results searchPhrase={`${q} ${byline} ${captions}`}>
              {list.map((item: ResultType, idx: number) => {
                switch (item.componentName) {
                  case 'story': return <StoryResult key={`story-${idx}`} index={idx} {...item.objectProp as StoryResultProps} />
                  case 'photo': return <PhotoResult key={`photo-${idx}`} index={idx} lightbox={usingLightbox} {...item.objectProp as PhotoResultProps} />
                  case 'page': return <PageResult key={`page-${idx}`} index={idx} {...item.objectProp as PageResultProps} />
                  case 'death_notice': return <DeathNoticeResult key={`death-${idx}`} index={idx} {...item.objectProp as DeathNoticeResultProps} />
                  default: return <LoadingSkeleton key={`skel-${idx}`} />
                }
              })}
            </Results>
          </div>
        </>
      }
      {displayError &&
        <div className="error-page">
          <Typography align='center'>
            <header className="page-icon">
              <WarningIcon style={{ fontSize: 150 }} color="action" />
            </header>
            <h1>
              Check your search keywords
            </h1>
            {lexicalError && <p>
              Your query is not valid due to a missing double-quote on a phrase or unbalanced parentheses.
            </p>}
            {syntaxError && <p>
              Your search keywords contain invalid boolean expression(s).
            </p>}
          </Typography>
        </div>
      }
      {state.page < state.size && <div className="search-result-actions">
        <Button
          color="primary"
          variant="contained"
          className="more-results-button"
          onClick={useMoreResults}
        >
          More Results
        </Button>
      </div>}
    </div>
  )
}
