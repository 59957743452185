import env from '../context/environment/initial';
import axios from 'axios';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _flattenDeep from 'lodash/flattenDeep';
import { convertPubDate } from '../utils/convertDate';
import { PublicationSelectionType, PublicationType } from '../globals/components/publicationRadioItems';

export type SearchOptions = {
  resultTypes: ObjectTypes[],
  betweenDates: string[],
  prevDates: string,
  year: string,
  byline: string,
  captions: string,
  publication: string,
  status: string,
  sortBy: string,
  from?: number,
  viewRestrictedResults?: boolean,
  size?: number,
}

export enum ObjectTypes {
  PHOTO = 1,
  TEXT = 2,
  DEATH_NOTICE = 3,
  PDF = 4,
}

const generalSearchFields = [
  "HEADLINE",
  "BYLINE",
  "TEXT",
  "PUBLISHED_CAPTION",
  "SOURCE_CAPTION",
  "PHOTOGRAPHER",
  "SECTION",
  "PAGE_ALPHA",
  "PUB_LINK",
  "DATELINE",
  "MEMO",
  "PUBLISHED_CORRECTION",
  "UNPUB_CORRECTION",
  "TEXT_KEYWORD",
  "SHOOT_DATE",
  "EDITION",
];

const esEndpoint = `${env.REACT_APP_ELASTIC_SEARCH_ENDPOINT}/_search`;

export const filterObjectTypes = (resultType: string) => {
  switch (resultType) {
    case 'photo': return ObjectTypes.PHOTO;
    case 'story': return ObjectTypes.TEXT;
    case 'page': return ObjectTypes.PDF;
    case 'death_notice':
    default: return ObjectTypes.DEATH_NOTICE;
  }
};

export const translateState = (recordSecurity?: number) => {
  if (recordSecurity === null) {
    return null;
  }

  switch (recordSecurity) {
    case 2: return "Auto-Archived";
    case 3: return "Archived";
    case 7: return "Needs Review";
    case 9: return "Killed";
    default: return `Status #${recordSecurity}`;
  }
};

export const construct = (q: string | undefined | null, {
  resultTypes,
  betweenDates,
  prevDates,
  year,
  byline,
  captions,
  publication,
  status,
  sortBy,
  from,
  viewRestrictedResults,
  size = 25,
}: SearchOptions) => {
  const mainKeywordMatching = q ? {
    query_string: {
      query: q,
      fields: generalSearchFields,
    },
  } : {
    match_all: {},
  };

  const includeObjectTypeMatching = resultTypes && resultTypes.length > 0 && {
    bool: {
      should: _map(resultTypes, (objType: ObjectTypes) => ({
        term: {
          OBJ_TYPE: objType,
        },
      })),
    }
  };

  const rangeQueryTemplate = (condition: any) => ({
    range: {
      PUB_DATE: {
        ...condition,
      }
    }
  });

  const includeDate = (betweenDates && betweenDates.length == 1 && {
    term: {
      PUB_DATE: convertPubDate(betweenDates[0]+ 'T05:00:00.000Z'),
    },
  });

  const includeRange = (betweenDates && betweenDates.length == 2 && {
    ...rangeQueryTemplate({
      gte: convertPubDate(betweenDates[0]+ 'T05:00:00.000Z'),
      lte: convertPubDate(betweenDates[1]+ 'T05:00:00.000Z'),
    })
  }) || (prevDates && {
    ...rangeQueryTemplate({
      gte: `now-${(() => {
        switch (prevDates) {
          case 'last_7_days': return '7d';
          case 'last_30_days': return '30d';
          case 'last_12_months':
          default: return '12M';
        }
      })()}/d`,
      lte: 'now',
    })
  }) || (year && {
    ...rangeQueryTemplate({
      gte: `${year}-01-01`,
      lte: 'now,'
    })
  });

  const includeBylinePhotographer = byline && {
    query_string: {
      query: byline,
      fields: ['BYLINE', 'PHOTOGRAPHER'],
    }
  };

  const includeCaptions = captions && {
    query_string: {
      query: captions,
      fields: ['PUBLISHED_CAPTION'],
    }
  };

  const includePublication = publication 
    && publication !== PublicationSelectionType.INQUIRER_AND_DAILY_NEWS
    && {
      term: {
        "PUBLICATION.keyword": (() => {
          switch (publication) {
            case PublicationSelectionType.INQUIRER:
              return PublicationType.INQUIRER;
            case PublicationSelectionType.DAILY_NEWS:
            default:
              return PublicationType.DAILY_NEWS;
          }
        })(),
      }
    };

  const includeStatus = status && {
    term: {
      "RECORD_SECURITY": parseInt(status),
    }
  };

  const includeSort = (() => {
    switch (sortBy) {
      case 'newest':
      case 'oldest':
        return {
          sort: {
            PUB_DATE: {
              order: sortBy === 'newest' ? 'desc' : 'asc',
            },
          },
        };
      case 'byline':
        return {
          sort: {
            [`${sortBy.toUpperCase()}.keyword`]: {
              order: 'asc',
            },
          },
        };
      case 'lead_graph':
      case 'page_alpha':
      case 'section':
        return {
          sort: [
            {
              _script: {
                type: "string",
                script: {
                  lang: "painless",
                  source: `return params._source.${sortBy.toUpperCase()}.toLowerCase()`,
                },
                order: "asc",
              },
            },
          ],
        };
    }
  })();

  const includeRestrictedResults = viewRestrictedResults === false && {
    range: {
      RECORD_SECURITY: {
        gt: 3,
      },
    },
  };

  const includeNonNullRestrictedResults = viewRestrictedResults === false && {
    exists: {
      field: 'RECORD_SECURITY',
    },
  };

  return {
    track_total_hits: true,
    from,
    size,
    query: {
      bool: {
        must: [
          mainKeywordMatching,
          includeBylinePhotographer,
          includeCaptions,
          includeNonNullRestrictedResults,
        ].filter(Boolean),
        filter: _map([
          includeDate,
          includeRange,
          includeObjectTypeMatching,
          includePublication,
          includeStatus,
        ]).filter(Boolean),
        must_not: [
          includeRestrictedResults,
        ].filter(Boolean),
      }
    },
    ...includeSort,
  };
};

const safety = (q?: string | null) => (
  q?.split(' ')
    .map((word: string) => (
      word.match(/[^\s]+-[^\s]*/gi) ? word.replace('-', '*') : word
    ))
    .join(' ')
);

export const search = (q: string | undefined | null, searchOptions: SearchOptions, bearerToken?: string) => {
  return axios.post(esEndpoint, construct(safety(q), searchOptions), {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    }
  });
};

export const manualSearch = (dsl: string, bearerToken?: string) => {
  return axios.post(esEndpoint, dsl, {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  });
};

export default search;