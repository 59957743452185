import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import useMultiKeyPress from '../../utils/useMultiKeyPress';
import QueryDebug from './QueryDebug';
import Modal from '@material-ui/core/Modal/Modal';

export interface DebugProps {

}

export const Debug: React.FC<DebugProps> = ({
  ...props
}) => {
  const shortcutPressed = useMultiKeyPress('i', 'n', 'q');
  const [debugMode, activateDebug] = useState(false);
  const [isOpenQueryDebug, openQueryDebug] = useState(false);

  useEffect(() => {
    if (shortcutPressed) {
      activateDebug(!debugMode);
    }
  }, [shortcutPressed]);

  return (
    <>
      <Modal
        className="query-mode-modal"
        open={isOpenQueryDebug}
        onClose={() => openQueryDebug(false)}
      >
        <QueryDebug onClose={() => openQueryDebug(false)}/>
      </Modal>
      <div onClick={() => activateDebug(false)}>
        <Drawer anchor="right" open={debugMode}>
          <List>
            <ListItem>
              <ListItemText>Debug Mode</ListItemText>
            </ListItem>
            <ListItem button 
              onClick={() => openQueryDebug(true)}
              disabled={window.location.pathname !== '/search'}
            >
              <ListItemIcon><CodeIcon/></ListItemIcon>
              <ListItemText primary="ElasticSearch Query" />
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>
  );
};

export default Debug;