import React from 'react';
import _get from 'lodash/get';
import HeaderLink from '../../components/Header/HeaderLink';
import { isDisplayFeature } from '../../utils/toggleShowFeatures/checkIsDisplayKey';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';

export const HeaderMenuItems = () => {
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  return (
    <>
      <HeaderLink key='Pdf' url='/pdf' label='SHOW-PDF' isDisplay={isDisplayFeature('REACT_APP_HIDE_PDF')} />
      {editPermitted && <HeaderLink key='Section' url='/section' label='S-MANIFEST' />}
      <HeaderLink key='Feedback' url={_get(env, 'REACT_APP_URL_FEEDBACK', '')} label='Feedback' external />
      <HeaderLink key='Help' url={_get(env, 'REACT_APP_URL_HELP', '')} label='Help' external />
    </>
  );
}

export default HeaderMenuItems;
