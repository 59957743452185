import _unescape from 'lodash/unescape';

const characterEntities = [
  ['', /\+\n/g],
  ['&', /&#38;/g],
  ['>', /&#62;/g],
  ['<', /&#60;/g],
  ['"', /&#34;/g],
  ["'", /&#39;/g],
  ['\n\n', /\n/g], 
];

export const decodeHTML = (text: string = '') => {
  characterEntities.forEach(([entity, encodedCharacter]) => {
    text = text.replace(encodedCharacter as RegExp, entity as string);
  });

  const element = document.createElement('div');
  const entityPattern = /&(?:#x[a-f0-9]+|#[0-9]+|[a-z0-9]+);?/gi;
  const found = text.match(entityPattern) || [];

  found.forEach((entity) => {
    element.innerHTML = entity;
    const encodedEntity = element.textContent || '';
    text = text.replace(entity, encodedEntity);
    element.textContent = '';
  });

  return text;
};

export default decodeHTML;