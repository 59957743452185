import { ParsedUrlQuery } from 'querystring';
import useQueryString, {getQueryString} from './useQueryString';

export interface ResultsQueryString extends ParsedUrlQuery {
  q: string,
  resultTypes: string,
  betweenDates: string,
  prevDates: string,
  year: string,
  byline: string,
  captions: string,
  publication: string,
  status: string,
  sortBy: 'oldest' | 'newest' | 'relevant',
  start: string,
}

export const useResultsQueryString = () => useQueryString() as ResultsQueryString;
export const getResultsQueryString = () => getQueryString() as ResultsQueryString;

export default useResultsQueryString;
