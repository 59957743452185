import React, { createContext, useState, useContext } from 'react';
import { WebAuth } from 'auth0-js';
import { useEnvironmentState } from '../environment';
import { isValidURL } from '../../utils/isValidURL';

export interface AuthContext {
  auth0?: WebAuth,
  authState?: AuthState,
  updateAuthState?: any,
}

export type AuthState = {
  accessToken: string | null,
  idToken: string | null,
  expiresAt?: number,
  roles: string[],
  email?: string,
  name?: string,
  picture?: string,
}

export interface AuthProviderProps {}

const useAuthInstance = () => {
  const env = useEnvironmentState();

  const callbackUrl = `${env.REACT_APP_CALLBACK_URL}`;
  const redirectUri = isValidURL(callbackUrl) ? callbackUrl :
    window.location.origin + '/callback';

  return new WebAuth({
    domain: `${env.REACT_APP_AUTH0_DOMAIN}`,
    clientID: `${env.REACT_APP_AUTH0_CLIENT_ID}`,
    redirectUri,
    responseType: 'token id_token',
  });
}

export const Auth0Context = createContext<AuthContext>({});

const useAuthState = () => {
  return useState<AuthState>({
    accessToken: localStorage.getItem('accessToken'),
    idToken: localStorage.getItem('idToken'),
    expiresAt: +`${localStorage.getItem('expiresAt')}`,
    roles: (localStorage.getItem('roles') || '').split('|'),
  });
};

const useContextValue = () => {
  const [authState, updateAuthState] = useAuthState();
  return {
    auth0: useAuthInstance(),
    authState,
    updateAuthState
  };
};

export const Auth0Provider: React.FC<AuthProviderProps> = ({ children }) => {
  const value = useContextValue();
  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};

export const useAuth0Context = () => {
  return useContext(Auth0Context);
};

export default Auth0Provider;