import env from '../context/environment/initial';
import axios, { AxiosResponse } from 'axios';
import { Source } from '../pages/details/DetailsPage';

export interface GetDocumentOptions {
  bearerToken: string,
}

const esDocumentEndpoint = `${env.REACT_APP_ELASTIC_SEARCH_ENDPOINT}/document`;

export const getDocument = <T>(id: string, {
  bearerToken,
}: GetDocumentOptions) => axios.get<T>(
  `${esDocumentEndpoint}/${id}`,
  {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  },
);

export const editDocument = (id: string, newSource: Source, bearerToken: string): Promise<AxiosResponse<Source>> =>
  axios.post(`${env.REACT_APP_API_BASE_URL}/record-update/${id}`, newSource, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });

export const getDocumentByPdf = (path: string, name: string, bearerToken: string): Promise<AxiosResponse<Source>> => (
  axios.post(`${env.REACT_APP_API_BASE_URL}/_search`, {
    size: 1,
    query: {
      bool: {
        must: [
          {
            prefix: {
              ['H_PATH.keyword']: path,
            },
          }, {
            term: {
              ['PAGE_ALPHA.keyword']: name,
            },
          },
        ],
      },
    },
  }, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  })
);

export enum Publications {
  PHILADELPHIA_DAILY_NEWS = 'PHILADELPHIA DAILY NEWS',
  PHILADELPHIA_INQUIRER = 'PHILADELPHIA INQUIRER',
}

export enum Graphics {
  PHOTO = 'PHOTO',
  GRAPHIC = 'GRAPHIC',
  CARTOON = 'CARTOON',
  DRAWING = 'DRAWING',
  CHART = 'CHART',
  MAP = 'MAP',
  DIAGRAM = 'DIAGRAM',
}

export default getDocument;