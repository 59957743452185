import React, { ChangeEvent, useState } from 'react';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import './PdfDetails.scss';
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  createStyles,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import DetailsMetaInfo, {
  DetailsMetaInfoProps,
} from '../../components/Details/DetailsMetaInfo';
import PageBox, { PageState } from '../PageBox/PageBox';
import ShareBox from '../ShareBox/ShareBox';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { Source } from '../../pages/details/DetailsPage';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';
import { useAuth0Context } from '../../context/auth';
import { useEffect } from 'react';
import { getPagesBySection } from '../../services/pdfList';

export interface PdfDetailsProps extends DetailsBehaviorProps {
  title: string;
  subtitle: string;
  byline: string;
  content: string;
  pdf_path: string;
  pdf_page: string;
  metaInfo: DetailsMetaInfoProps;
  cloneSource: Source;
  onClickSubmitUpdateDocument: (clonedSource: Source) => void;
  handleOnSourceChanges: (key: string, value: string) => void;
}

export interface DetailsBehaviorProps {
  loading?: boolean;
}

type Variant = 'h4' | 'h6' | 'body1';

export interface PortionProps {
  variant?: Variant;
  component?: string;
}

export const Portion: React.FC<PortionProps> = ({ children, ...props }) => {
  return (
    <div className='details-portion'>
      <Grid item>
        <Typography {...props}>{children}</Typography>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    'edit-button': {
      marginTop: 10,
    },
    'title-field': {
      width: 'auto',
      marginBottom: 15,
      marginRight: 10
    }
  })
);

export const PdfDetails: React.FC<PdfDetailsProps> = ({
  title,
  subtitle,
  byline,
  content,
  pdf_path,
  pdf_page,
  metaInfo,
  loading,
  cloneSource,
  handleOnSourceChanges,
  onClickSubmitUpdateDocument,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [pages, setPages] = useState<PageState[]>([]);
  const classes = useStyles();
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);
  const {authState} = useAuth0Context();
  const bearerToken = authState?.idToken;
  const pdfFilePath = `${pdf_path.replace('/FINAL/', '/FINAL')}/${pdf_page}.pdf`;

  useEffect(() => {
    getPagesBySection(
      metaInfo.date || '',
      metaInfo.publication || '',
      metaInfo.edition || '',
      metaInfo.section || null,
      bearerToken || ''
    ).then((results) => {
      const hits = results.data.hits.hits;
      const pages: PageState[] = hits.map((hit: any) => ({
        id: hit._id,
        page: hit._source.PAGE_ALPHA,
      }));
      setPages(_sortBy(_uniqBy(pages, 'page'), ['page']));
    });
  }, []);

  const _renderPdfTitle = (): JSX.Element => (
    <Portion variant='h4'>
      {loading ? (
        <Typography variant='body1'>
          <Skeleton width={300} variant='text' />
        </Typography>
      ) : (
        pdf_page
      )}
      {metaInfo.section && ' ' + metaInfo.section}
    </Portion>
  );

  const _renderPdfEditTittle = (): JSX.Element => (
    <>
      <TextField
        variant='outlined'
        value={_get(cloneSource, 'PAGE_ALPHA', '')}
        className={classes['title-field']}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          handleOnSourceChanges('PAGE_ALPHA', event.target.value)
        }
      />
      <TextField
        variant='outlined'
        value={_get(cloneSource, 'SECTION', '')}
        className={classes['title-field']}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          handleOnSourceChanges('SECTION', event.target.value)
        }
      />
    </>
  );

  return (
    <div className='pdf-details'>
      <div className='left-area'>
        <Grid container justify='center'>
          {isEdit ? _renderPdfEditTittle() : _renderPdfTitle()}
          {loading ? (
            <Typography>
              <Skeleton width={300} variant='text' />
            </Typography>
          ) : (
            <iframe
              src={pdfFilePath}
              height="750px"
              width="100%"
            />
          )}
        </Grid>
      </div>
      <div className='right-area'>
        <Grid container justify='center' spacing={3}>
          <Grid item xs={12}>
            {loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted &&
              (isEdit === false ? (
                <Button
                  className={classes['edit-button']}
                  variant='contained'
                  color='primary'
                  onClick={() => setIsEdit(!isEdit)}
                >
                  Edit this story
                </Button>
              ) : (
                <ButtonGroup
                  className={classes['edit-button']}
                  variant='contained'
                  color='primary'
                  aria-label='outlined primary button group'
                >
                  <Button onClick={() => setIsEdit(!isEdit)}>
                    Back to preview mode
                  </Button>
                  <Button
                    onClick={() => {
                      onClickSubmitUpdateDocument(cloneSource);
                    }}
                  >
                    Update
                  </Button>
                </ButtonGroup>
              ))}
          </Grid>
          <Grid item xs={12}>
            <DetailsMetaInfo
              loading={loading}
              {...metaInfo}
              isEdit={isEdit}
              handleOnSourceChanges={handleOnSourceChanges}
              cloneSource={cloneSource}
            />
          </Grid>
          <Grid item xs={12}>
            <ShareBox
              downloadLink={pdfFilePath}
              formEnabled={false}
            />
          </Grid>
          <Grid item xs={12}>
            <PageBox
              currentPage={metaInfo.id || ''}
              pages={pages}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PdfDetails;
