import moment from 'moment';

export const convertDate = (datetime: string) => {
  const ts = new Date(datetime);
  const year = ts.getFullYear();
  const month = ts.toLocaleString('en-us', { month: 'long' });
  const date = ts.getDate();
  return month + ' ' + date + ', ' + year;
}

export const convertPubDate = (datetime: string) => {
  const ts = new Date(datetime);
  const isoTs = ts.toISOString();
  return moment(isoTs).format('YYYY-MM-DD');
}

export const convertPubDateForPdf = (datetime: string) => {
  const ts: Date = new Date(datetime);
  const isoTs = ts.toISOString();

  return moment(isoTs).format('YYYYMMDD');
}

export const convertPubDateOffset = (datetime: string) => convertDate(datetime + 'T05:00:00.000Z');

export default convertDate;
