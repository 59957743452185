import React from 'react';
import './PageBox.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

export interface PageState {
  id: string;
  page: string;
}

export interface PageBoxProps {
  currentPage: string;
  pages: PageState[];
}

export const PageBox: React.FC<PageBoxProps> = ({
  currentPage,
  pages,
  ...props
}) => {
  const history = useHistory();

  return (
    <div className='page-box'>
      <Grid container spacing={2}>
        {pages.map(({page, id}) => (
          <Grid item xs={3}>
            <Button
              className='page-button'
              variant={currentPage == id ? 'contained' : 'outlined'}
              disabled={currentPage == id}
              onClick={() => {
                history.push(`/document/${id}`)
              }}
            >
              {page}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PageBox;
