import React from 'react';
import './Pagination.scss';
import Typography from '@material-ui/core/Typography';
import { NavLink, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { Grid } from '@material-ui/core';
import {usePagination} from '../../utils/usePagination';
import useQueryString, { querify } from '../../utils/useQueryString';
import Skeleton from '@material-ui/lab/Skeleton';
import FormControl from '@material-ui/core/FormControl/FormControl';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import Divider from '@material-ui/core/Divider/Divider';

export interface PaginationProps {
  loading?: boolean,
}

export const Pagination: React.FC<PaginationProps> = ({
  loading
}) => {
  const history = useHistory();
  const {state} = usePagination();
  const qs = useQueryString();
  const sortBy = (sortBy: string) => querify({
    ...qs,
    sortBy,
    page: 0,
  });
  const TextSkeleton = () => (
    <Typography variant="subtitle1">
      <Skeleton width={500}/>
    </Typography>
  );

  const pageTotal: number = state.page;
  const total: number = _get(state, typeof state.size === 'object' ? 'size.value' : 'size', 0);

  const handleSortByClick = (event: any) => {
    const selected = event.target.value;

    if (!selected) {
      history.push(`?${sortBy('relevant')}`);
      return;
    }

    if (qs.sortBy !== selected) {
      history.push(`?${sortBy(selected)}`);
    }
  };

  return (
    <div className="pagination-toolbar">
      <div className="header-left">
        <div className='label'>
          {loading ? <TextSkeleton/> : <Typography>{pageTotal} of {total} result{total !== 1 && 's'}</Typography>}
        </div>
      </div>
      <div className="header-right">
      <Typography>
        <Grid container spacing={1}>
          <Grid item xs={4} className='label'>
            <Typography>
              Sort by:
            </Typography>
          </Grid>
          <Grid className="pagination-sort-tools" item xs={6}>
            <FormControl variant="outlined" className="sort-tools-dropdown">
              <Select
                defaultValue={qs.sortBy || 'relevant'}
                onChange={handleSortByClick}
              >
                <MenuItem value='relevant'>Relevance</MenuItem>
                <MenuItem value='newest'>Newest</MenuItem>
                <MenuItem value='oldest'>Oldest</MenuItem>
                <Divider style={{padding: 3}}/>
                <MenuItem value='byline'>Byline</MenuItem>
                <MenuItem value='lead_graph'>Lead Graph</MenuItem>
                <MenuItem value='page_alpha'>Page</MenuItem>
                <MenuItem value='section'>Section</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        </Typography>
      </div>
    </div>
  );
};

export default Pagination;