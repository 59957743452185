import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
} from '@material-ui/core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _replace from 'lodash/replace';
import { IPdfObject } from '../../utils/types/pdfResponseType';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FolderIcon from '@material-ui/icons/Folder';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useEnvironmentState } from '../../context/environment';
import { Document, Page, pdfjs, Outline } from 'react-pdf';
import { useSnackbar } from 'notistack';
import sendToVendor, { SendToVendorOptions } from '../../services/sendToVendor';
import './ListPdf.scss';
import { convertPubDateForPdf } from '../../utils/convertDate';
import { useAuth0Context } from '../../context/auth';
import { getDocumentByPdf } from '../../services/documentSearch';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  child: IPdfObject[];
  path: string;
  date: Date;
}

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

export const PdfFolder: React.FC<IProps> = ({ child, path, date }) => {
  const [collapse, setCollapse] = useState<boolean[]>([]);
  const [openPdfModal, setOpenPdfModal] = React.useState(false);
  const [numPages, setNumPages] = useState(1);
  const [url, setUrl] = useState('');

  const env = useEnvironmentState();
  const {authState} = useAuth0Context();
  const bearerToken = authState?.idToken || '';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<any>([]);

  const handleClickCollapse = (index: number) => {
    const tmpCollap: boolean[] = [...collapse];
    tmpCollap[index] = !collapse[index];

    setCollapse(tmpCollap);
  };

  const onOpenPdfModal = (path: string): void => {
    setUrl(
      `https://cors-anywhere.herokuapp.com/${_get(
        env,
        'REACT_APP_API_BASE_URL',
        ''
      )}/pdf-reader?filePath=${convertPubDateForPdf(
        date.toString()
      )}/${encodeURI(path)}`
    );
    setOpenPdfModal(true);
  };

  const openDocument = (path: string, name: string) => {
    const pubDate = convertPubDateForPdf(
      date.toString(),
    );
    const finalPath = `/data/pdf/${pubDate}/${path}`;
    const finalName = _replace(name, '.pdf', '');
    getDocumentByPdf(finalPath, finalName, bearerToken).then(response => {
      const id = _get(response, 'data.hits.hits[0]._id');

      if (id) {
        const win = window.open(`/document/${id}`, '_blank');
        win?.focus();
        return;
      }

      enqueueSnackbar('Could not find the document from the selected PDF.', {
        variant: 'error',
        action: toasterDismissButton,
      });
    });
  };

  const OnClosePdfModal = () => {
    setOpenPdfModal(false);
  };

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  const toasterDismissButton = (key: React.ReactText) => (
    <Button color='primary' onClick={() => closeSnackbar(key)}>
      DISMISS
    </Button>
  );

  const onSendToVendor = (loadingKey: any, {publisher, publishedDate, section}: SendToVendorOptions) => () => {
    setLoading([...loading, loadingKey]);

    const key = enqueueSnackbar(`Sending ${publisher} / ${publishedDate} / ${section} to vendor`, { 
      variant: 'info',
      persist: true,
      action: toasterDismissButton,
    });

    sendToVendor({publisher, publishedDate, section}, bearerToken).then((result) => {
      setLoading(loading.filter((k: any) => k !== loadingKey));
      
      const normalLogs = _get(result, 'data.result.stdout');
      const errorLogs = _get(result, 'data.result.stderr');

      closeSnackbar(key);
      
      console.log('STDOUT:');
      console.log(normalLogs);

      if (errorLogs) {
        console.log('\nSTDERR:');
        console.log(errorLogs);

        enqueueSnackbar('Could not be able to process. Check the browser console logs for more info.', {
          variant: 'warning',
          persist: true,
          action: toasterDismissButton,
        });
      } else {
        enqueueSnackbar('Successfully sent!', {
          variant: 'success',
          persist: true,
          action: toasterDismissButton,
        });
      }
    }).catch(() => {
      closeSnackbar(key);
      setLoading(loading.filter((k: any) => k !== loadingKey));
      enqueueSnackbar('Server unavailable. Check the browser console logs for more info.', {
        variant: 'error',
        persist: true,
        action: toasterDismissButton,
      });
    });
  };

  const renderPdfList = () => {
    if (_isEmpty(child) === false) {
      return (
        <>
          <List>
            {_map(child, (collapseTitle: IPdfObject[], idx: number) => {
              const sectionName = _get(collapseTitle, 'name', '');
              const sectionKey = `${path}/${sectionName}`;
              return <div key={idx} className='threadlist__subreddit_thread'>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={sectionName}
                  />
                  <Button 
                    variant='contained'
                    disabled={loading.indexOf(sectionKey) >= 0}
                    startIcon={loading.indexOf(sectionKey) >= 0 ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                    onClick={onSendToVendor(sectionKey, {
                      publisher: path,
                      publishedDate: convertPubDateForPdf(
                        date.toString()
                      ),
                      section: sectionName,
                    })}
                  >
                    Send to vendor
                  </Button>
                </ListItem>
                <div>
                  {renderPdfTree(
                    _get(collapseTitle, 'child', []), 
                    sectionKey,
                  )}
                </div>
                <Divider />
              </div>
            })}
          </List>
          <Dialog
            onClose={OnClosePdfModal}
            open={openPdfModal}
            maxWidth={false}
          >
            <div className='pdf-viewer-container'>
              <Document
                file={url}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
                <Outline />
              </Document>
            </div>
          </Dialog>
        </>
      );
    }

    return <></>;
  };

  const renderPdfTree = (child: IPdfObject[], path: string): JSX.Element => {
    if (_isEmpty(child) === false) {
      return (
        <Grid container spacing={1} className='pdf-files-container'>
          {_map(child, (pdfObj: IPdfObject[], idx: number) => (
            <>
              {_isEmpty(_get(pdfObj, 'child[0]', '')) === false ? (
                <>
                  {renderPdfTree(
                    _get(pdfObj, 'child', []),
                    `${path}/${_get(pdfObj, 'name', '')}`
                  )}
                </>
              ) : (
                  <Grid key={`${idx}-${_get(pdfObj, 'name', '')}`} item>
                    <Button
                      color={_get(pdfObj, 'name') === "..." ? "default" : "primary"}
                      variant="contained"
                      onClick={() =>
                        openDocument(path, _get(pdfObj, 'name', ''))
                      }
                    >
                     {_replace(_get(pdfObj, 'name', ''), '.pdf', '')}
                    </Button>
                  </Grid>
                )}
            </>
          ))}
        </Grid>
      );
    }

    return <></>;
  };

  return renderPdfList();
};
