import React from 'react';
import HttpsRedirect from 'react-https-redirect';
 
export const HttpsApp = ({children}) => (
  <HttpsRedirect>
    {children}
  </HttpsRedirect>
);

export default HttpsApp;
