import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export enum PublicationType {
  INQUIRER_AND_DAILY_NEWS = 'PHILADELPHIA INQUIRER AND THE DAILY NEWS',
  INQUIRER = 'PHILADELPHIA INQUIRER',
  DAILY_NEWS = 'PHILADELPHIA DAILY NEWS',
}

export enum PublicationSelectionType {
  INQUIRER_AND_DAILY_NEWS = 'inquirer_and_daily_news',
  INQUIRER = 'inquirer',
  DAILY_NEWS = 'daily_news',
}

export const publicationRadioItems: Array<JSX.Element> = [
  <FormControlLabel value={PublicationSelectionType.INQUIRER} control={<Radio size="small"/>} label="Inquirer" />,
  <FormControlLabel value={PublicationSelectionType.DAILY_NEWS} control={<Radio size="small"/>} label="Daily News" />,
  <FormControlLabel value={PublicationSelectionType.INQUIRER_AND_DAILY_NEWS} control={<Radio size="small"/>} label="Inquirer &amp; Daily News" />,
];

export default publicationRadioItems;