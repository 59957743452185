import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _pickBy from 'lodash/pickBy';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import SearchBar from '../../components/Search/SearchBar';
import AdvancedSearch from '../../components/Search/AdvancedSearch';
import DatePicker, { SingleYearPickerWithRef } from '../../components/DatePicker/DatePicker';
import { BlockDates } from '../../utils/searchFilterTools';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import useKeyPress from '../../utils/useKeyPress';
import './SearchForm.scss';
import { querify } from '../../utils/useQueryString';
import Grid from '@material-ui/core/Grid';
import useResultsQueryString from '../../utils/useResultsQueryString';
import { convertPubDateOffset } from '../../utils/convertDate';
import moment from 'moment';
import { useEnvironmentState } from '../../context/environment';

const searchBarFilterCheckboxes: Array<{ label: string, value: string }> = [
  { label: 'Story', value: 'story' },
  { label: 'Photo', value: 'photo' },
  { label: 'Page', value: 'page' },
  { label: 'Death Notice', value: 'death_notice' },
];

const dateFilterButtons: Array<{ label: string, value: any }> = [
  { label: 'Last 7 days', value: BlockDates.LAST_7_DAYS },
  { label: 'Last 30 days', value: BlockDates.LAST_30_DAYS },
  { label: 'Last 12 months', value: BlockDates.LAST_12_MONTHS },
];

export interface SearchFormProps {
  layout: 'main' | 'refine',
}

export const SearchForm: React.FC<SearchFormProps> = ({
  layout,
  ...props
}) => {
  const env = useEnvironmentState();
  const queryParams = useResultsQueryString();
  const resultTypes = queryParams.resultTypes?.split(',') || searchBarFilterCheckboxes.map(({ value }) => value);
  const [betweenDates, setBetweenDates] = useState(queryParams.betweenDates?.split(','));

  const [
    dateFilterSelection,
    toggleDateFilterSelection,
  ] = useState<string | null>(null);

  const searchBarInput = React.createRef<HTMLInputElement>();
  const filterSelections: Array<React.RefObject<HTMLInputElement>> = searchBarFilterCheckboxes.map(
    () => React.createRef<HTMLInputElement>(),
  );
  const lightboxSelectionRef = React.createRef<HTMLInputElement>();
  const yearInput = React.createRef<HTMLInputElement>();
  const dateRangeInput = React.createRef<HTMLInputElement>();
  const advancedSearchRef = React.createRef<AdvancedSearch>();
  const [lightboxEnabled, setLightboxEnabled] = useState(false);
  const history = useHistory();
  const searchPress = useKeyPress('Enter');

  const handleYearChange = (year: number) => {
    const endYear = moment([year]).endOf('year');
    const prevYear = moment([year]).startOf('year');
    setBetweenDates([prevYear, endYear].map(date => date.format('YYYY-MM-DD')));
    toggleDateFilterSelection(null);
  };

  const handleDateRangeChange = () => {
    toggleDateFilterSelection(null);
    _get(yearInput, 'current.reset', _noop)();
  }

  const PerformSearchButton = () => (
    <Button
      color="primary"
      variant="contained"
      className="search-button"
      onClick={lightboxEnabled ? performLightboxSearch : performSearch}
    >
      Search
    </Button>
  );

  const NewSearchButton = () => (
    <Button className="new-search-button" href="/">
      <Typography>New Search</Typography>
    </Button>
  );

  const performSearch = () => {
    const qs: any = {
      q: searchBarInput.current?.value,
      resultTypes: _map(filterSelections, ({ current }) => (
        current?.checked && current?.value)
      ).filter(Boolean).join(),
      betweenDates: dateRangeInput.current?.value,
      byline: advancedSearchRef.current?.getByline(),
      captions: advancedSearchRef.current?.getCaptions(),
      publication: advancedSearchRef.current?.getPublication(),
      sortBy: 'relevant',
      page: 0,
    };

    const status = advancedSearchRef.current?.getStatus();
    if (status !== 'any') {
      qs['status'] = status;
    }

    const encodedQs = querify(qs);
    const path = `/search?${encodedQs}`;
    history.push(path);
    history.go(0);
  };

  const performLightboxSearch = () => {
    const qs: any = {
      q: searchBarInput.current?.value,
      resultTypes: 'lightbox',
      ...advancedSearchRef.current?.getLightboxSearchQuery(),
      betweenDates: dateRangeInput.current?.value,
      sortBy: 'relevant',
      page: 0,
    };

    const encodedQs = querify(qs);
    const path = `/search?${encodedQs}`;
    history.push(path);
    history.go(0);
  };

  useEffect(() => {
    const today = moment();
    switch (dateFilterSelection) {
      case BlockDates.LAST_7_DAYS:
        const prev7days = moment().subtract(7, 'day');
        setBetweenDates([prev7days, today].map(date => date.format('YYYY-MM-DD')));
        break;
      case BlockDates.LAST_30_DAYS:
        const prev30days = moment().subtract(30, 'day');
        setBetweenDates([prev30days, today].map(date => date.format('YYYY-MM-DD')));
        break;
      case BlockDates.LAST_12_MONTHS:
        const prev12months = moment().subtract(12, 'month');
        setBetweenDates([prev12months, today].map(date => date.format('YYYY-MM-DD')));
        break;
    }
  }, [dateFilterSelection]);

  useEffect(() => {
    if (searchPress) {
      performSearch();
    }
  }, [searchPress]);

  return (
    <div className="search-form">
      <div className="search-bar-content">
        <Grid container spacing={3}>
          <Grid item xs={layout === 'main' ? 12 : 7}>
            <SearchBar
              ref={searchBarInput}
              defaultValue={queryParams.q}
            />
          </Grid>
          {layout === 'refine' &&
            <Grid item xs={5}>
              <div className="action-content header-right action-right">
                <PerformSearchButton />
                <NewSearchButton />
              </div>
            </Grid>
          }
        </Grid>
      </div>
      <div className="search-bar-filter-content">
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {searchBarFilterCheckboxes.map((params, i) => (
              <FormControlLabel
                inputRef={filterSelections[i]}
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={resultTypes.includes(params.value)}
                    disabled={lightboxEnabled}
                  />
                }
                labelPlacement="end"
                {...params}
              />
            ))}
            <FormControlLabel
              inputRef={lightboxSelectionRef}
              control={
                <Checkbox
                  color="primary"
                />
              }
              labelPlacement="end"
              label="Lightbox"
              onClick={() => {
                setLightboxEnabled(lightboxSelectionRef.current?.checked || false);
              }}
            />
          </FormGroup>
        </FormControl>
      </div>
      <Divider />
      <div className="date-filter-content">
        <div className="header-left">
          <DatePicker
            range
            ref={dateRangeInput}
            defaultDates={betweenDates && betweenDates.map(date => new Date(convertPubDateOffset(date)))}
            onChange={handleDateRangeChange}
          />
        </div>
        <div className="header-right">
          {dateFilterButtons.map(({ label, value }) => (
            <ToggleButton
              selected={dateFilterSelection === value}
              onChange={() => {
                toggleDateFilterSelection(value);
                _get(yearInput, 'current.reset', _noop)();
              }}
            >{label}</ToggleButton>
          ))}
          <SingleYearPickerWithRef
            ref={yearInput}
            onYearChange={handleYearChange}
          />
        </div>
      </div>
      <div className="advanced-search-content">
        <AdvancedSearch
          ref={advancedSearchRef}
          byline={queryParams.byline}
          captions={queryParams.captions}
          publication={queryParams.publication}
          status={queryParams.status}
          lightboxEnabled={lightboxEnabled}
        />
      </div>
      {layout === 'main' &&
        <div className="action-content">
          <div className="header-left action-left">
            <Typography>Looking for additional help?</Typography>
            <Link to={{ pathname: env.REACT_APP_URL_HELP || '' }} target='_blank'>
              <Typography>Click here</Typography>
            </Link>
          </div>
          <div className="header-right action-right">
            <NewSearchButton />
            <PerformSearchButton />
          </div>
        </div>
      }
    </div>
  );
};

export default SearchForm;
