import React, { ChangeEvent, useState } from 'react';
import _get from 'lodash/get';
import './PhotoDetails.scss';
import {
  Grid,
  Typography,
  TextField,
  Button,
  makeStyles,
  createStyles,
  ButtonGroup,
  CircularProgress,
} from '@material-ui/core';
import DetailsMetaInfo, {
  FieldContainer,
  DetailsMetaInfoProps,
} from '../../components/Details/DetailsMetaInfo';
import ShareBox from '../ShareBox/ShareBox';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import ClearIcon from '@material-ui/icons/Clear';
import ArchiveIcon from '@material-ui/icons/Archive';
import decodeHTML from '../../utils/decodeHTML';
import { Source } from '../../pages/details/DetailsPage';
import { decodeHTMLForEditField } from '../../utils/searchHelper';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';

export interface PhotoDetailsProps extends DetailsBehaviorProps {
  title: string;
  subtitle: string;
  byline: string;
  content: string;
  photo_path: string;
  source_caption: string;
  published_caption: string;
  source: string;
  credit: string;
  shoot_date: string;
  photographer: string;
  metaInfo: DetailsMetaInfoProps;
  cloneSource: Source;
  isSubmitting: boolean;
  onClickSubmitUpdateDocument: (clonedSource: Source) => void;
  handleOnSourceChanges: (key: string, value: string) => void;
  handleChangeStatus: (status: number) => void;
}

export interface DetailsBehaviorProps {
  loading?: boolean;
}

type Variant = 'h4' | 'h6' | 'body1';

export interface PortionProps {
  variant?: Variant;
  component?: string;
}

export const Portion: React.FC<PortionProps> = ({ children, ...props }) => {
  return (
    <div className='details-portion'>
      <Grid item>
        <Typography {...props}>{children}</Typography>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    'title-field': {
      width: '100%',
      marginBottom: 15,
    },
    'edit-button': {
      marginBottom: 15,
    },
    'content-field': {
      width: '100%',
    },
    'content-container': {
      marginTop: 15,
    },
  })
);

export const PhotoDetails: React.FC<PhotoDetailsProps> = ({
  title,
  subtitle,
  byline,
  content,
  photo_path,
  source_caption,
  published_caption,
  source,
  credit,
  shoot_date,
  photographer,
  metaInfo,
  loading,
  cloneSource,
  isSubmitting,
  handleChangeStatus,
  handleOnSourceChanges,
  onClickSubmitUpdateDocument,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [actionInUse, setActionInUse] = useState('');
  const classes = useStyles();
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  const _renderTitle = (): JSX.Element => (
    <Portion variant='h4'>
      {source_caption
        ? decodeHTML(source_caption)
        : decodeHTML(published_caption)}
    </Portion>
  );

  const _renderEditTitle = (): JSX.Element => (
    <TextField
      variant='outlined'
      multiline
      className={classes['title-field']}
      value={
        source_caption
          ? decodeHTMLForEditField(_get(cloneSource, 'SOURCE_CAPTION', ''))
          : decodeHTMLForEditField(_get(cloneSource, 'PUBLISHED_CAPTION', ''))
      }
      onChange={(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleOnSourceChanges(source_caption ? 'SOURCE_CAPTION' : 'PUBLISHED_CAPTION', event.target.value)}
    />
  );

  return (
    <div className='photo-details'>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Grid container justify='center'>
            {isEdit ? _renderEditTitle() : _renderTitle()}
            {loading ? (
              <Typography>
                <Skeleton width={300} variant='text' />
              </Typography>
            ) : (
              <img
                width='100%'
                height='100%'
                src={photo_path}
                alt='main_photo'
              />
            )}
          </Grid>
          <Grid
            container
            justify='center'
            className={classes['content-container']}
          >
            <FieldContainer
              label='Source Caption:'
              value={decodeHTMLForEditField(_get(cloneSource, 'SOURCE_CAPTION', ''))}
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='SOURCE_CAPTION'
            />
            <FieldContainer
              label='Published Caption:'
              value={decodeHTMLForEditField(_get(cloneSource, 'PUBLISHED_CAPTION', ''))}
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='PUBLISHED_CAPTION'
            />
            <FieldContainer
              label='Photographer:'
              value={_get(cloneSource, 'PHOTOGRAPHER', '')}
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='PHOTOGRAPHER'
            />
            <FieldContainer
              label='Credit:'
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              value={_get(cloneSource, 'CREDIT', '')}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='CREDIT'
            />
            <FieldContainer
              label='Source:'
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              value={_get(cloneSource, 'SOURCE', '')}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='SOURCE'
            />
            <FieldContainer
              label='Shoot Date:'
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              value={_get(cloneSource, 'SHOOT_DATE', '')}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='SHOOT_DATE'
            />
            <FieldContainer
              label='Slug:'
              loading={loading}
              isEditField={isEdit}
              variant='outlined'
              isFullSize={true}
              value={_get(cloneSource, 'PUB_LINK', '')}
              handleOnSourceChanges={handleOnSourceChanges}
              fieldName='PUB_LINK'
            />
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container justify='center' className='details-meta-info-sticky' spacing={3}>
            <Grid item xs={12}>
              {loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted &&
                (isEdit === false ? (
                  <Button
                    className={classes['edit-button']}
                    variant='contained'
                    color='primary'
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Edit this story
                  </Button>
                ) : (
                  <ButtonGroup
                   className={classes['edit-button']}
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Button onClick={() => setIsEdit(!isEdit)}>
                      Back to preview mode
                    </Button>
                    <Button onClick={() => {
                      onClickSubmitUpdateDocument(cloneSource)
                    }}>Update</Button>
                  </ButtonGroup>
                ))}
            </Grid>
            <Grid item xs={12}>
              {(loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted && !isEdit &&
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={isSubmitting && actionInUse === 'archive' ? <CircularProgress size={20}/> : <ArchiveIcon />}
                      disabled={isSubmitting}
                      onClick={() => {
                        setActionInUse('archive');
                        handleChangeStatus(3);
                      }}
                    >
                      Archive
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={isSubmitting && actionInUse === 'kill' ? <CircularProgress size={20}/> : <ClearIcon />}
                      disabled={isSubmitting}
                      onClick={() => {
                        setActionInUse('kill');
                        handleChangeStatus(9);
                      }}
                    >
                      Kill
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <DetailsMetaInfo
                loading={loading}
                {...metaInfo}
                isEdit={isEdit}
                handleOnSourceChanges={handleOnSourceChanges}
                cloneSource={cloneSource}
              />
            </Grid>
            <Grid item xs={12}>
              <ShareBox
                downloadLink={photo_path}
                formEnabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhotoDetails;
