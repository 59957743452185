import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '../../utils/useAuth';
import LinearProgress from '@material-ui/core/LinearProgress';

export interface CallbackProps {}

export const Callback: React.FC<CallbackProps> = ({
  ...props
}) => {
  const { handleAuthentication } = useAuth0();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    } else {
      history.push('/');
    }
  }, [handleAuthentication, location]);

  return (
    <div>
      <LinearProgress />
    </div>
  );
};

export default Callback;