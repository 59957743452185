import React from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import './SearchBar.scss';

export interface SearchBarProps {
  keyword?: string,
  defaultValue?: string,
}

export const SearchBar: React.ForwardRefRenderFunction<HTMLInputElement, SearchBarProps> = ({
  keyword,
  defaultValue,
  ...props
}, ref) => {
  return (
    <div className="search-bar">
      <TextField
        inputRef={ref}
        defaultValue={defaultValue}
        className="search-bar-input"
        variant="outlined"
        placeholder="Keyword search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default React.forwardRef(SearchBar);