import React from 'react';

interface IProps {
  photoDetail: JSX.Element;
  storyDetail: JSX.Element;
  deathNoticeDetail: JSX.Element;
  pdfDetail: JSX.Element;
  lightBoxDetail: JSX.Element;
  type: number;
}
const SwitchView: React.FC<IProps> = ({
  photoDetail,
  storyDetail,
  deathNoticeDetail,
  pdfDetail,
  lightBoxDetail,
  type
}) => {
  switch (type) {
    case 1:
      return photoDetail;
    case 2:
      return storyDetail;
    case 3:
      return deathNoticeDetail;
    case 4:
      return pdfDetail;
    case 5:
      return lightBoxDetail;
    default:
      return <div>unknown {type}</div>;
  }
}

export default SwitchView;