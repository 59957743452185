import React, { createContext, useContext, useReducer } from 'react';
import initialState from './initial';

const EnvironmentStateContext = createContext(initialState);
const EnvironmentDispatchContext = createContext(initialState);

function environmentReducer() {
  throw new Error(`Dispatch not supported for Environment Provider`);
}

export default function EnvironmentProvider({ children }) {
  const [state, dispatch] = useReducer(environmentReducer, initialState);

  return (
    <EnvironmentStateContext.Provider value={{ ...state }}>
      <EnvironmentDispatchContext.Provider value={{ dispatch }}>
        {children}
      </EnvironmentDispatchContext.Provider>
    </EnvironmentStateContext.Provider>
  );
};

export function useEnvironmentState() {
  const context = useContext(EnvironmentStateContext);
  if (context === undefined) {
    throw new Error('useEnvironmentState must be used within the Environment\'s Provider');
  }
  return context;
}

export function useEnvironmentDispatch() {
  const context = useContext(EnvironmentDispatchContext);
  if (context === undefined) {
    throw new Error('useEnvironmentDispatch must be used within a CountProvider');
  }
  return context;
}