import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';
import './index.scss';
import ForceHTTPS from './utils/redirectHTTPS';
import * as serviceWorker from './utils/serviceWorker';
import AuthProvider, { useAuth0Context } from './context/auth';
import { useAuth0 } from './utils/useAuth';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Homepage } from './pages/homepage/Homepage';
import { ResultsPage } from './pages/results-page/ResultsPage';
import { DetailsPage } from './pages/details/DetailsPage';
import ListPdf from './pages/pdf-page/ListPdf';
import Section from './pages/section-page/Section';
import Header from './components/Header/Header';
import HeaderMenuItems from './globals/components/HeaderMenuItems';
import Callback from './pages/callback/Callback';
import PaginationProvider from './utils/usePagination';
import DebugMode from './components/Debug/Debug';
import SearchQueryProvider from './context/searchQuery';
import { Helmet } from 'react-helmet';
import { useEnvironmentState } from './context/environment';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserLink from './components/Header/UserLink';
import useRoles from './utils/useRoles';

const Pages = () => {
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  return (
    <>
      <Route key='detail' path="/document/:id" component={DetailsPage} />
      <Route key='lightbox' path="/lightbox/:id" component={DetailsPage} />
      <Route key='search' path="/search" component={ResultsPage} />
      <Route key='pdf' path="/pdf" component={ListPdf} />
      {editPermitted && <Route key='section' path="/section" component={Section} />}
      <Route key='home' exact path="/" component={Homepage} />
    </>
  )
};

const HeaderLinks: React.FC = () => {
  const {logout} = useAuth0();
  const {authState} = useAuth0Context();
  
  return (
    <>
      <HeaderMenuItems/>
      <UserLink
        name={authState?.name}
        email={authState?.email}
        handleLogout={logout}
        imgSrc={authState?.picture}
      />
    </>
  );
};

const AlwaysAuthenticated: React.FC = ({children}) => {
  const {renewSession, isAuthenticated} = useAuth0();

  useEffect(() => {
    renewSession();
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      {isAuthenticated && children}
    </React.Fragment>
  );
};

const Meta: React.FC = () => {
  const env = useEnvironmentState();

  return (
    <Helmet>
      <link rel="icon" href={`/favicon${env.REACT_APP_ENVIRONMENT || ''}.ico`} />
    </Helmet>
  );
};

const toastOpts: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const doccenterTheme = createTheme({
  palette: {
    primary: {
      500: '#043c8d',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ForceHTTPS>
      <Meta/>
      <AuthProvider>
        <MuiThemeProvider theme={doccenterTheme}>
          <SnackbarProvider
              anchorOrigin={toastOpts}
          >
            <PaginationProvider>
              <SearchQueryProvider>
                <Router>
                  <DebugMode/>
                  <Header label="DocCenter 2.0">
                    <HeaderLinks/>
                  </Header>
                  <Switch>
                    <Route path="/callback" component={Callback} />
                    <AlwaysAuthenticated>
                      <Pages/>
                    </AlwaysAuthenticated>
                  </Switch>
                </Router>
              </SearchQueryProvider>
            </PaginationProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </AuthProvider>
    </ForceHTTPS>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
