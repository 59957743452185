import React, {useState, useContext, useEffect, useRef} from 'react';
import { NavLink } from 'react-router-dom';
import _times from 'lodash/times';
import _truncate from 'lodash/truncate';
import Highlighter from '../Highlighter/Highlighter';
import './Results.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { CircularProgress, Typography } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import LocalFloristOutlinedIcon from '@material-ui/icons/LocalFloristOutlined';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent/CardContent';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';
import { translateState } from '../../services/elasticSearch';
import useResizer from '../../utils/useResizer';
import decodeHTML from '../../utils/decodeHTML';

export interface StandardResultProps {
  id?: number;
  index?: number;
}

export interface StoryResultProps extends StandardResultProps {
  header: string,
  date: string,
  author: string,
  section: string,
  page: string,
  edition: string,
  publisher: string,
  wordCount: number,
  body: string,
  status: number,
  slug: string,
}

export interface PhotoResultProps extends StandardResultProps {
  source?: string,
  thumbnail?: string,
  publishedCaption?: string,
  sourceCaption?: string,
  photographer?: string,
  shootDate?: string,
  pubDate?: string,
  status?: number,
  slug?: string,
  lightbox?: boolean,
}

export interface PageResultProps extends StandardResultProps {
  source: string,
  date: string,
  page: string,
  edition: string,
  publisher: string,
}

export interface DeathNoticeResultProps extends StandardResultProps {
  header: string,
  date: string,
  body: string,
}

export interface ResultsProps {
  searchPhrase?: string,
}

export interface ThumbnailProps {
  src: string;
}

export interface ResultItemProps {
  index?: number,
  documentId?: number,
  type: ResultItemType,
  thumbnail?: string,
  lightbox?: boolean,
}

export type ResultItemType =
  | 'story'
  | 'photo'
  | 'page'
  | 'death_notice'
  | 'skeleton';

const ResultType = {
  story: <DescriptionOutlinedIcon/>,
  photo: <ImageOutlinedIcon/>,
  page: <PictureAsPdfOutlinedIcon/>,
  death_notice: <LocalFloristOutlinedIcon/>,
  skeleton: <Skeleton variant="circle"><Avatar/></Skeleton>,
};

const Thumbnail: React.FC<ThumbnailProps> = React.memo(({
  src,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentSrc, updateSrc] = useState('');
  const resizedUrl = useResizer(src);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = resizedUrl;
    imageToLoad.onload = () => {
      setLoading(false);
      updateSrc(resizedUrl);
    };
    imageToLoad.onerror = () => {
      setError(true);
    };
  }, []);

  if (loading || error) {
    return (
      <div className='result-thumbnail'>
        {error ? <BrokenImageOutlinedIcon/> : <CircularProgress/>}
      </div>
    );
  }

  return (
    <img className="result-thumbnail" src={currentSrc} alt="thumbnail"/>
  );
});

const ResultItem: React.FC<ResultItemProps> = ({
  index,
  documentId,
  type,
  thumbnail,
  children,
  lightbox,
  ...props
}) => {
  const resultItemRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (index && index < 5) {
      setVisible(true);
      return;
    }

    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(entries[0].target);
        observer.disconnect();
      }
    });

    resultItemRef && resultItemRef.current && observer.observe(resultItemRef.current);
    
    return () => observer.disconnect();
  }, [type, resultItemRef]);

  const isViewable = () => (
    thumbnail && (
      thumbnail.toLowerCase().endsWith('.jpg') ||
      thumbnail.toLowerCase().endsWith('.png')
    )
  );

  return (
    <CardActionArea component="div" disableRipple>
      <NavLink
        to={lightbox ? `/lightbox/${documentId}` : `/document/${documentId || ''}`}
        target="_blank"
        style={{ color: 'inherit', textDecoration: 'inherit'}}
        onClick={(e) => type === 'skeleton' && e.preventDefault()}
      >
        <CardContent>
          <div ref={resultItemRef}>
            {isVisible && (
              <Grid container>
                <Grid item xs={thumbnail ? 2 : 1}>
                  {isViewable() && thumbnail ? <Thumbnail src={thumbnail} /> : ResultType[type]}
                </Grid>
                <Grid item xs={thumbnail ? 10: 11}>
                  {children}
                </Grid>
              </Grid>
            )}
          </div>
        </CardContent>
      </NavLink>
    </CardActionArea>
  );
};

export const ResultItemSkeleton = () => (
  <ResultItem type="skeleton">
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography><Skeleton/></Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography><Skeleton/></Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography><Skeleton/></Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography><Skeleton/></Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography><Skeleton/></Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="div" variant="h3">
          <Skeleton/>
        </Typography>
      </Grid>
    </Grid>
  </ResultItem>
);

export const LoadingSkeleton: React.FC<{}> = () => (
  <React.Fragment>
    {_times(10, () => <ResultItemSkeleton/>)}
  </React.Fragment>
);

export const StoryResult: React.FC<StoryResultProps> = ({
  index, id, header, date, author, section, page, edition, publisher, wordCount, body, status, slug,
}) => {
  const env = useEnvironmentState();
  const searchPhrase = useHighlighter();

  return (
    <ResultItem type='story' documentId={id} index={index}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            <b>
              {decodeHTML(header)
                .split('\n')
                .filter(Boolean)
                .map(title => <Highlighter {...{searchPhrase}}>{title}</Highlighter>)
                .map(node => <>{node}<br/></>)}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {date} - {
              decodeHTML(author)
                .split('\n')
                .filter(Boolean)
                .map(line => <Highlighter {...{searchPhrase}}>{line}</Highlighter>)
                .map(node => <>{node}<br/></>)
            }
          </Typography>
          {useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE) && (
            <Typography>
              <b>Status</b> {translateState(status)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>
            <b>Section </b>
            {section}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>
            <b>Page </b>
            {page}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>
            <b>Edition </b>
            <Highlighter {...{searchPhrase}}>{edition}</Highlighter>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>{publisher}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>{wordCount} words</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>
            <b>ID </b>
            {id}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>
            <b>Slug </b>
            {slug}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            <Highlighter {...{searchPhrase}}>
              {_truncate(body, {length: 300})}
            </Highlighter>
          </Typography>
        </Grid>
      </Grid>
    </ResultItem>
  );
};

export const PhotoResult: React.FC<PhotoResultProps> = ({
  index,
  id,
  source,
  thumbnail,
  publishedCaption,
  sourceCaption,
  photographer,
  shootDate,
  pubDate,
  status,
  slug,
  lightbox,
}) => {
  const env = useEnvironmentState();
  const searchPhrase = useHighlighter();

  return (
    <ResultItem type='photo' thumbnail={thumbnail} documentId={id} index={index} lightbox={lightbox}>
      <Grid
        className='result-fields'
        container
        direction='column'
        justify='space-between'
      >
        <Grid container>
          <Grid item xs>
            <Typography>
              <div className={publishedCaption ? '' : 'disabled-label'}>
                <b>Published Caption</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              <Highlighter {...{searchPhrase}}>
                {publishedCaption}
              </Highlighter>
            </Typography>
          </Grid>
        </Grid>

        {sourceCaption && <Grid container>
          <Grid item xs>
            <Typography>
              <div className={sourceCaption ? '' : 'disabled-label'}>
                <b>Source Caption</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              <Highlighter {...{searchPhrase}}>
                {sourceCaption}
              </Highlighter>
            </Typography>
          </Grid>
        </Grid>}

        {useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE) && status && (
          <Grid container>
            <Grid item xs>
              <Typography>
                <div className={status ? '' : 'disabled-label'}>
                  <b>Status</b>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{translateState(status)}</Typography>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1}>
          <Grid item xs>
            <Typography>
              <div className={photographer ? '' : 'disabled-label'}>
                <b>Photographer</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              <Highlighter {...{searchPhrase}}>
                {photographer}
              </Highlighter>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography>
              <div className={pubDate ? '' : 'disabled-label'}>
                <b>Pub date</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{pubDate}</Typography>
          </Grid>
          {slug && <><Grid item xs>
            <Typography>
              <div className={slug ? '' : 'disabled-label'}>
                <b>Slug</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{slug}</Typography>
          </Grid></>}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography>
              <div className={shootDate ? '' : 'disabled-label'}>
                <b>Shoot date</b>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>{shootDate}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ResultItem>
  );
};

export const PageResult: React.FC<PageResultProps> = ({
  index,
  id,
  date,
  page,
  edition,
  publisher,
}) => {
  return (
    <ResultItem type="page" documentId={id} index={index}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <Typography>{date}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography><b>Page </b>{page}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>{edition}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography>{publisher}</Typography>
        </Grid>
      </Grid>
    </ResultItem>
  );
};

export const DeathNoticeResult: React.FC<DeathNoticeResultProps> = ({
  index,
  id,
  header,
  date,
  body,
}) => {
  return (
    <ResultItem type="death_notice" documentId={id} index={index}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            <b>{header}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {date}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{body}</Typography>
        </Grid>
      </Grid>
    </ResultItem>
  );
};

const HighlighterContext = React.createContext<{searchPhrase?: string}>({});
const useHighlighter = () => {
  const {searchPhrase} = useContext(HighlighterContext);
  return searchPhrase || '';
};

export const Results: React.FC<ResultsProps> = ({
  searchPhrase,
  children,
  ...props
}) => {
  return (
    <div className="search-results">
      <Divider/>
      <HighlighterContext.Provider value={{searchPhrase}}>
        <Grid container direction="column">
          {React.Children.map(children, child => [
            <Box p={1}>
              {child}
            </Box>,
            <Divider/>
          ])}
        </Grid>
      </HighlighterContext.Provider>
    </div>
  );
};

export default Results;
