import React, {createContext, useContext, useReducer} from 'react';

export interface PaginationState {
  page: number,
  size: number,
}

export enum PaginationActionType {
  SET_PAGE,
  SET_TOTAL_HITS,
}

export type PaginationAction =
  | { type: PaginationActionType, value?: any };

const paginationActions = (state: any, action: PaginationAction) => {
  switch (action.type) {
    case PaginationActionType.SET_PAGE: return {
      ...state, 
      page: action.value,
    };
    case PaginationActionType.SET_TOTAL_HITS: return {
      ...state,
      size: action.value,
    };
    default: return state;
  }
};

const initialPaginationState: PaginationState = {
  page: 0,
  size: 0,
};

const PaginationContext = createContext<{
  state: PaginationState,
  dispatch: React.Dispatch<PaginationAction>,
}>({
  state: initialPaginationState,
  dispatch: () => {},
});

export const PaginationProvider: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(paginationActions, initialPaginationState);

  return (
    <PaginationContext.Provider value={{state, dispatch}}>
      {children}
    </PaginationContext.Provider>
  )
};

export const usePagination = () => {
  return useContext(PaginationContext)
};

export default PaginationProvider;