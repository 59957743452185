import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import './UserLink.scss';
import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export interface UserLinkProps {
  name?: string;
  email?: string;
  imgSrc?: string;
  handleLogout?: () => void;
}

export const UserLink: React.FC<UserLinkProps> = ({
  name,
  email,
  imgSrc,
  handleLogout,
}) => {
  const [dropdown, setOpenDropDownMenu] = useState(false);
  
  let delayHandler = setTimeout(() => {}, 0);
  const handleMouseLeave = () => {
    delayHandler = setTimeout(() => {
      setOpenDropDownMenu(false)
    }, 300);
  }

  const handleMouseEnter = () => {
    setOpenDropDownMenu(true);
    clearTimeout(delayHandler);
  }

  return name ? (
    <>
      <div className='custom-menu-button'>
        <div className='avatar' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleMouseEnter}>
          <Avatar src={imgSrc}>
            {name.match(/\b(\w)/g)?.join('').toUpperCase().substr(0, 2)}
          </Avatar>
        </div>
        <div
          className={`header-menu-dropdown header-dropdown ${dropdown ? 'drop-animated-activated' : 'drop-animated'}`}
          onMouseEnter={() => dropdown && handleMouseEnter()}
          onMouseLeave={handleMouseLeave}
        >
          <div className='content'>
            <div className='profile-menu-container'>            
              <p>
                Signed in as <strong>{name}</strong><br/>
                ({email})
              </p>
              <div className='action-buttons'>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<ExitToAppIcon/>}
                  onClick={handleLogout}
                  style={{
                    backgroundColor: "#fff",
                    color: '#043c8d',
                    padding: '5px 10px',
                  }}
                >
                  Sign out
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${dropdown ? 'page-mask' : 'page-mask-none'}`}></div>
    </>
  ) : <></>;
};

export default UserLink;
