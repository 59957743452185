import { Button } from '@material-ui/core';
import { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _truncate from 'lodash/truncate';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DeathNoticeDetails from '../../components/Details/DeathNoticeDetails';
import { DetailsMetaInfoProps } from '../../components/Details/DetailsMetaInfo';
import LightBoxDetails from '../../components/Details/LightBoxDetail';
import PdfDetails from '../../components/Details/PdfDetails';
import PhotoDetails from '../../components/Details/PhotoDetails';
import StoryDetails from '../../components/Details/StoryDetails';
import { useAuth0Context } from '../../context/auth';
import env from '../../context/environment/initial';
import getDocument, { editDocument } from '../../services/documentSearch';
import { translateState } from '../../services/elasticSearch';
import { getLightbox } from '../../services/lightboxSearch';
import decodeHTML from '../../utils/decodeHTML';
import {
  differenceSource,
  handleUrl,
  transformMetaInfo
} from '../../utils/searchHelper';
import './DetailsPage.scss';
import SwitchView from './SwitchView';

export interface DetailsPageProps { }

export type Source = {
  HEADLINE?: string;
  PUBLISHED_CAPTION?: string;
  BYLINE?: string;
  TEXT?: string;
  PUBLICATION?: string;
  MODIFIED_DATE?: string;
  EDITION?: string;
  PAGE_ALPHA?: string;
  ID?: string;
  SECTION?: string;
  LENGTH?: string;
  GRAPHICS?: string;
  OBJ_TYPE?: number;
  H_PATH?: string;
  DAY?: string;
  PUB_DATE?: string;
  SOURCE_CAPTION?: string;
  SOURCE?: string;
  CREDIT?: string;
  SHOOT_DATE?: string;
  PHOTOGRAPHER?: string;
  PUB_LINK?: string;
  RECORD_SECURITY?: number;
  DATELINE?: string;
  LEAD_GRAPH?: string;
  TEXT_KEYWORDS?: string;
  MEMO?: string;
  PUBLISHED_CORRECTION?: string;
  UNPUBLISHED_CORRECTIONS?: string;
  PAGE_TYPE?: string;
  PDF_PATH?: string;
};

export type LightBoxSource = {
  typeId?: number;
  assignId?: string;
  assignSourceId?: number;
  statusId?: number;
  locationId?: number;
  locationPath?: string;
  locationName?: string;
  userId?: number;
  shootDate?: string;
  createDate?: string;
  updateDate?: string;
  expireDate?: string;
  archive?: number;
  spike?: number;
  filename?: string;
  origFilename?: string;
  extension?: string;
  mimeType?: string;
  body?: string;
  groupId?: number;
  keywords?: string;
  credit?: string;
  city?: string;
  state?: string;
  source?: string;
  size?: number;
  cdId?: number;
  objectId?: number;
}

export const DetailsPage: React.FC<DetailsPageProps> = ({ }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [source, setSource] = useState<Source>({});
  const [lightBoxSource, setLightBoxSource] = useState<LightBoxSource>({});
  const [cloneSource, setCloneSource] = useState<Source>(source);
  const [metaInfo, setMetaInfo] = useState<DetailsMetaInfoProps>({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { authState } = useAuth0Context();
  const bearerToken = authState?.idToken;
  const [objType, setObjType] = useState<number>(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const populate = async () => {
      if (location && /\/lightbox\/(\d+)/.test(location.pathname)) {
        const { data } = await getLightbox<{ _source: LightBoxSource }>(id, {
          bearerToken: bearerToken || '',
        });

        const { _source } = data;
        setLightBoxSource({ ..._source, objectId: Number(id), body: decodeHTML(_source.body) });
        setLoading(false);
        // Assign 5 for light box type
        setObjType(5);
      } else {
        const { data } = await getDocument<{ _source: Source }>(id, {
          bearerToken: bearerToken || '',
        });

        const { _source } = data;
        setSource({ ..._source, ID: id, HEADLINE: decodeHTML(_source.HEADLINE) });
        setCloneSource({ ..._source, ID: id, HEADLINE: decodeHTML(_source.HEADLINE) });
        setMetaInfo(transformMetaInfo(_source, id));
        setLoading(false);
        setObjType(_source.OBJ_TYPE || 0);
      }
    };

    populate();
  }, [id]);

  useEffect(() => {
    const ids = localStorage.getItem('result_stack')?.split(',');
    const newIds = ids?.slice(ids.indexOf(id) + 1) || [];
    localStorage.setItem('result_stack', newIds.join());
  }, []);

  const toasterDismissButton = (key: React.ReactText) => (
    <Button color='primary' onClick={() => closeSnackbar(key)}>
      DISMISS
    </Button>
  );

  let debounceInput = setTimeout(() => { }, 1000);
  const handleOnSourceChanges = (key: string, value: string): void => {
    clearTimeout(debounceInput);
    debounceInput = setTimeout(() => setCloneSource({
      ...cloneSource,
      [key]: value,
    }), 500);
  };

  const handleChangeStatus = (status: number) => {
    if (status !== 3 && status !== 9) {
      return true;
    }

    setIsSubmitting(true);

    if (source.ID && bearerToken) {
      editDocument(source.ID, { RECORD_SECURITY: status }, bearerToken)
        .then(() => {
          setIsSubmitting(false);
          setMetaInfo({ ...metaInfo, status: translateState(status) });

          const ids = localStorage.getItem('result_stack')?.split(',');

          if (ids && ids.length > 0) {
            const id = ids[0];
            const newIds = ids.slice(1);
            localStorage.setItem('result_stack', newIds.join());
            history.push(`/document/${id}`);
          }
        })
    }
  };

  const onSubmitUpdateDocument = (): void => {
    const diffSource = differenceSource(source, cloneSource);

    if (_isEmpty(diffSource) === false && source.ID && bearerToken) {
      setIsSubmitting(true);
      const key = enqueueSnackbar('Updating document...', {
        variant: 'info',
        persist: true,
        action: toasterDismissButton,
      });

      editDocument(source.ID, diffSource, bearerToken)
        .then(() => {
          const newSource = {
            ...source,
            ...diffSource,
          };

          setSource(newSource);
          setMetaInfo(transformMetaInfo(newSource, id));

          enqueueSnackbar(`Document #${source.ID} has been successfully updated.`, {
            variant: 'success',
            autoHideDuration: 2000,
            action: toasterDismissButton,
          });
        })
        .catch((err: AxiosError) => {
          console.log(err);

          enqueueSnackbar('Server unavailable. Check the browser console logs for more info.', {
            variant: 'error',
            persist: true,
            action: toasterDismissButton,
          });
        }).finally(() => {
          closeSnackbar(key);
          setIsSubmitting(false);
        });
    }
  };

  const _StoryDetail = () => (
    <>
      <Helmet defer={false}>
        <title>{_truncate(source.HEADLINE, { length: 50 })} - DocCenter 2.0</title>
      </Helmet>
      <StoryDetails
        title={source.HEADLINE || ''}
        captions={source.PUBLISHED_CAPTION}
        byline={source.BYLINE}
        content={source.TEXT || ''}
        metaInfo={metaInfo}
        loading={loading}
        cloneSource={cloneSource}
        isSubmitting={isSubmitting}
        disableUpdateButton={_isEmpty(differenceSource(source, cloneSource))}
        onClickSubmitUpdateDocument={onSubmitUpdateDocument}
        handleOnSourceChanges={handleOnSourceChanges}
        handleChangeStatus={handleChangeStatus}
      />
    </>
  );

  const _DeathNoticeDetail = () => (
    <>
      <Helmet defer={false}>
        <title>{_truncate(source.HEADLINE, { length: 50 })} - DocCenter 2.0</title>
      </Helmet>
      <DeathNoticeDetails
        title={source.HEADLINE || ''}
        byline={source.BYLINE}
        content={source.TEXT || ''}
        metaInfo={metaInfo}
        loading={loading}
        cloneSource={cloneSource}
        onClickSubmitUpdateDocument={onSubmitUpdateDocument}
        handleOnSourceChanges={handleOnSourceChanges}
      />
    </>
  );

  const _PdfDetail = () => {
    const pdf_path = `${env.REACT_APP_S3_IMAGE_SERVER}/${handleUrl(
      source.H_PATH
    )}`;

    return (
      <>
        <Helmet defer={false}>
          <title>{source.PAGE_ALPHA} {source.PUB_DATE} - DocCenter 2.0</title>
        </Helmet>
        <PdfDetails
          title={source.HEADLINE || ''}
          subtitle={source.PUBLISHED_CAPTION || ''}
          byline={source.BYLINE || ''}
          content={source.TEXT || ''}
          pdf_path={pdf_path}
          pdf_page={source.PAGE_ALPHA || ''}
          metaInfo={metaInfo}
          loading={loading}
          cloneSource={cloneSource}
          onClickSubmitUpdateDocument={onSubmitUpdateDocument}
          handleOnSourceChanges={handleOnSourceChanges}
        />
      </>
    );
  };

  const _PhotoDetail = () => {
    const photo_path = `${env.REACT_APP_S3_IMAGE_SERVER}/${handleUrl(
      source.H_PATH
    )}`;

    return (
      <>
        <Helmet defer={false}>
          <title>{source.PUB_LINK} - DocCenter 2.0</title>
        </Helmet>
        <PhotoDetails
          title={source.HEADLINE || ''}
          subtitle={source.PUBLISHED_CAPTION || ''}
          byline={source.BYLINE || ''}
          content={source.TEXT || ''}
          photo_path={photo_path}
          source_caption={source.SOURCE_CAPTION || ''}
          published_caption={source.PUBLISHED_CAPTION || ''}
          source={source.SOURCE || ''}
          credit={source.CREDIT || ''}
          shoot_date={source.SHOOT_DATE || ''}
          photographer={source.PHOTOGRAPHER || ''}
          metaInfo={metaInfo}
          loading={loading}
          cloneSource={cloneSource}
          isSubmitting={isSubmitting}
          onClickSubmitUpdateDocument={onSubmitUpdateDocument}
          handleOnSourceChanges={handleOnSourceChanges}
          handleChangeStatus={handleChangeStatus}
        />
      </>
    );
  };

  const _lightBoxDetail = () => {
    const photo_path = `${env.REACT_APP_S3_LIGHTBOX}/${handleUrl(
      `/InqData/Store${lightBoxSource.locationPath}/${id}_low.${lightBoxSource.extension}`
    )}`;
    return (<>
      <Helmet defer={false}>
        <title>{lightBoxSource.filename} - DocCenter 2.0</title>
      </Helmet>
      <LightBoxDetails
        title={lightBoxSource.body || ''}
        photo_path={photo_path}
        source={lightBoxSource.locationName || ''}
        shoot_date={lightBoxSource.shootDate || ''}
        photographer={lightBoxSource.credit || ''}
        loading={loading}
        cdId={Number(lightBoxSource.cdId)}
        objectId={Number(lightBoxSource.objectId)}
        file_name={lightBoxSource.origFilename || ''} />
    </>);
  }

  return (
    <>
      <div className='details-page'>
        <article className='details-content'>
          {loading ? (
            <StoryDetails loading={true}
              title=""
              content=""
              metaInfo={metaInfo}
              cloneSource={cloneSource}
              onClickSubmitUpdateDocument={() => { }}
              handleOnSourceChanges={() => { }}
              handleChangeStatus={() => { }}
            />
          ) : (
            <SwitchView
              photoDetail={_PhotoDetail()}
              storyDetail={_StoryDetail()}
              deathNoticeDetail={_DeathNoticeDetail()}
              pdfDetail={_PdfDetail()}
              lightBoxDetail={_lightBoxDetail()}
              type={objType}
            />
          )}
        </article>
      </div>
    </>
  );
};

export default DetailsPage;
