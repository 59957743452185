import React from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import {
  IAttributes,
  ISection,
  IText,
} from '../../utils/types/sectionResponseType';
import { Grid, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import './Section.scss';
import { sectionArr, startEndArr } from '../../utils/sectionHelper';
import { ISelect } from '../../utils/types/selectBoxType';

export interface IProps {
  sections: ISection[];
  setSections: React.Dispatch<React.SetStateAction<ISection[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    'MuiSelect-select': {
      minWidth: 100,
    },
  })
);

export const SectionComponent: React.FC<IProps> = ({
  sections,
  setSections,
}) => {
  const classes = useStyles();

  const handleOnChangeSection = (
    attributeName: string,
    value: IAttributes | IText,
    index: number
  ) => {
    const sectionsTemp = [...sections];
    sectionsTemp[index] = {
      ...sectionsTemp[index],
      [attributeName]: value,
    };

    setSections(sectionsTemp);
  };

  const handleOnDeleteSection = (removeIdx: number) => {
    const sectionsTemp = [...sections];

    setSections(_filter(sectionsTemp, (__, index: number) => (index !== removeIdx)));
  }

  return (
    <>
      {_map(sections, (section: ISection, index: number) => (
        <Grid container spacing={4} className='search-form' key={index}>
          <Grid item>
            {index === 0 && <h5>Section Name</h5>}
            <TextField
              value={_get(section, '_attributes.name', '')}
              variant='outlined'
              className='advanced-search-field'
              style={{ width: 300 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChangeSection(
                  '_attributes',
                  { name: e.target.value },
                  index
                )
              }
            />
          </Grid>
          <Grid item>
            {index === 0 && <h5>Section</h5>}
            <Select
              className={classes['MuiSelect-select']}
              variant='outlined'
              value={_get(section, 'letter._text', 'A')}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleOnChangeSection(
                  'letter',
                  { _text: e.target.value as string },
                  index
                )
              }
            >
              {_map(sectionArr(), (selectItem: ISelect, idx: number) => (
                <MenuItem value={selectItem.value} key={idx}>
                  {selectItem.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            {index === 0 && <h5>Start</h5>}
            <Select
              className={classes['MuiSelect-select']}
              variant='outlined'
              value={_get(section, 'start._text', '1')}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleOnChangeSection(
                  'start',
                  { _text: e.target.value as string },
                  index
                )
              }
            >
              {_map(startEndArr(), (selectItem: ISelect, idx: number) => (
                <MenuItem value={selectItem.value} key={idx}>
                  {selectItem.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            {index === 0 && <h5>End</h5>}
            <Select
              className={classes['MuiSelect-select']}
              variant='outlined'
              value={_get(section, 'end._text', '2')}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handleOnChangeSection(
                  'end',
                  { _text: e.target.value as string },
                  index
                )
              }
            >
              {_map(startEndArr(), (selectItem: ISelect, idx: number) => (
                <MenuItem value={selectItem.value} key={idx}>
                  {selectItem.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            {index === 0 && <h5>Remove</h5>}
            <IconButton color="primary" aria-label="delete" component="span" onClick={() => handleOnDeleteSection(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
