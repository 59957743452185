import axios, { AxiosPromise } from 'axios';
import _get from 'lodash/get';
import { ISectionResponse } from '../utils/types/sectionResponseType';

export const getListSection = (
  date: string,
  publication: string,
  bearerToken: string,
  url: string
): AxiosPromise<ISectionResponse> =>
  axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    params: {
      date,
      publication,
    },
  });

export const updateSection = (
  section: ISectionResponse,
  bearerToken: string,
  url: string
): AxiosPromise<ISectionResponse> =>
  axios.post(`${url}`, section, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    params: {
      date: _get(section, 'page_plan.pub._attributes.pubdate'),
      publication: _get(section, 'page_plan.pub._attributes.name'),
    },
  });
