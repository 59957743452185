import React, {useState, useCallback} from 'react';
import './ShareBox.scss';
import { Button, Paper, Typography } from '@material-ui/core';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import shareDocument from '../../services/documentShare';
import { useAuth0Context } from '../../context/auth';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { red, green } from '@material-ui/core/colors';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

export interface ShareBoxProps {
  loading?: boolean;
  formEnabled?: boolean;
  downloadLink?: string;
  bookmarkEnabled?: boolean;
}

export const ShareBox: React.FC<ShareBoxProps> = ({
  loading,
  formEnabled=true,
  bookmarkEnabled,
  downloadLink,
  ...props
}) => {
  const {authState} = useAuth0Context();
  const bearerToken = authState?.idToken || '';
  const {id} = useParams<{id: string}>();
  const [sendTo, setSendTo] = useState('email');
  const [wasEmailError, setEmailError] = useState(false);
  const [wasEmailSent, setEmailSent] = useState(false);
  const [isSending, toggleSending] = useState(false);
  const emailInput = React.createRef<HTMLInputElement>();
  const noteInput = React.createRef<HTMLInputElement>();

  const WithSkeleton: React.FC<{
    width?: number,
    height?: number,
  }> = ({
    width,
    height,
    children,
  }) => {
    return (
      loading ? <Skeleton width={width} height={height}/> : <>{children}</>
    );
  };

  const handleSelectedSendTo = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setSendTo(event.target.value as string);
  }, []);

  const handleSend = useCallback(() => {
    setEmailSent(false);
    setEmailError(false);
    toggleSending(true);
    shareDocument(id, {
      bearerToken,
      sendTo,
      recipient: emailInput.current?.value || '',
      note: noteInput.current?.value || '',
    }).then(() => {
      setEmailSent(true);
    }).catch((err) => {
      setEmailError(true);
    }).then(() => {
      toggleSending(false);
    });
  }, [emailInput, noteInput]);

  return (
    <div className="share-box">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className="details-action-box" elevation={0}>
            <Grid container>
              <div className="details-tool-bar">
                {bookmarkEnabled && <Button
                  startIcon={<BookmarkBorderOutlinedIcon/>}
                >
                  Bookmark
                </Button>}
                {downloadLink && <Button
                  startIcon={<GetAppIcon/>}
                  href={downloadLink + '?download=true'}
                >
                  Download
                </Button>}
              </div>
              {formEnabled && <Grid container direction="column" className="share-form">
                <Grid item className="share-component-form">
                  <WithSkeleton width={200} height={30}>
                    <InputLabel htmlFor="send-to-select">Send to</InputLabel>
                  </WithSkeleton>
                  <WithSkeleton height={40}>
                    <FormControl>
                      <Select
                        labelId="send-to-select"
                        variant="filled"
                        disabled={isSending}
                        value={sendTo}
                        onChange={handleSelectedSendTo}
                      >
                        <MenuItem value="email">Email</MenuItem>
                      </Select>
                    </FormControl>
                  </WithSkeleton>
                </Grid>
                <Grid item className="share-component-form">
                  <WithSkeleton width={200} height={30}>
                    <InputLabel>Email Address</InputLabel>
                  </WithSkeleton>
                  <WithSkeleton height={40}>
                    <TextField 
                      required 
                      inputRef={emailInput}
                      variant="filled"
                      disabled={isSending}
                    />
                  </WithSkeleton>
                </Grid>
                <Grid item className="share-component-form">
                  <WithSkeleton width={200} height={30}>
                    <InputLabel>Note</InputLabel>
                  </WithSkeleton>
                  <WithSkeleton height={50}>
                    <TextField
                      inputRef={noteInput}
                      multiline
                      rows={5}
                      variant="filled"
                      disabled={isSending}
                    />
                  </WithSkeleton>
                </Grid>
                <Grid item>
                  <div className="share-bottom-area">
                    <WithSkeleton width={100} height={40}>
                      <div className="send-status">
                        {wasEmailSent && <Typography
                          className="message-label"
                          style={{ color: green[500] }}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                          />
                          Email sent!
                        </Typography>}
                        {wasEmailError && <Typography
                          className="message-label"
                          style={{ color: red[500] }}
                        >
                          <ErrorIcon
                            fontSize="small"
                          />
                          Nothing was sent.
                        </Typography>}
                      </div>
                      <div className="send-actions">
                        <Button 
                          color="primary" 
                          variant="contained"
                          className="send-button"
                          disabled={isSending}
                          onClick={handleSend}
                        >
                          {wasEmailError ? 'Resend' : 'Send'}
                        </Button>
                      </div>
                    </WithSkeleton>
                  </div>
                </Grid>
              </Grid>}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShareBox;