import React, { ChangeEvent, useState } from 'react';
import _get from 'lodash/get';
import './DeathNoticeDetails.scss';
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  makeStyles,
  createStyles,
  ButtonGroup,
} from '@material-ui/core';
import DetailsMetaInfo, {
  DetailsMetaInfoProps,
} from '../../components/Details/DetailsMetaInfo';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { Source } from '../../pages/details/DetailsPage';
import decodeHTML from '../../utils/decodeHTML';
import useRoles from '../../utils/useRoles';
import { useEnvironmentState } from '../../context/environment';
import ShareBox from '../ShareBox/ShareBox';

export interface DeathNoticeDetailsProps extends DetailsBehaviorProps {
  title: string;
  byline?: string;
  content: string;
  metaInfo: DetailsMetaInfoProps;
  cloneSource: Source;
  onClickSubmitUpdateDocument: (clonedSource: Source) => void;
  handleOnSourceChanges: (key: string, value: string) => void;
}

export interface DetailsBehaviorProps {
  loading?: boolean;
}

type Variant = 'h4' | 'h6' | 'body1';

export interface PortionProps {
  variant?: Variant;
  component?: string;
}

export const Portion: React.FC<PortionProps> = ({ children, ...props }) => {
  return (
    <div className='details-portion'>
      <Grid item>
        <Typography {...props}>{children}</Typography>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    'edit-button': {
      marginBottom: 15,
    },
  })
);

export const DeathNoticeDetails: React.FC<DeathNoticeDetailsProps> = ({
  title,
  byline,
  content,
  loading,
  metaInfo,
  cloneSource,
  handleOnSourceChanges,
  onClickSubmitUpdateDocument,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const classes = useStyles();
  const env = useEnvironmentState();
  const editPermitted = useRoles(env.REACT_APP_DOCCENTER_EDITOR_ROLE);

  const _renderDeathNoteContent = (): JSX.Element => (
    <>
      <Portion variant='h4'>
        {loading ? (
          <Typography variant='body1'>
            <Skeleton width={300} variant='text' />
          </Typography>
        ) : (
          decodeHTML(title)
        )}
      </Portion>
      {byline && (
        <Portion>
          <b>
            {loading ? (
              <Typography>
                <Skeleton width={300} variant='text' />
              </Typography>
            ) : (
              decodeHTML(byline)
            )}
          </b>
        </Portion>
      )}
      <Portion variant='body1'>
        {loading ? (
          <Typography>
            <Skeleton width={300} variant='text' />
          </Typography>
        ) : (
          decodeHTML(content)
        )}
      </Portion>
    </>
  );

  const _renderDeathNoteEditContent = (): JSX.Element => (
    <>
      <TextField
        variant='outlined'
        className='title-text-field'
        value={_get(cloneSource, 'HEADLINE', '')}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleOnSourceChanges('HEADLINE', event.target.value)}
      />
      {byline && (
        <TextField
          variant='outlined'
          className='title-text-field margin-text-field'
          value={_get(cloneSource, 'BYLINE', '')}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => handleOnSourceChanges('BYLINE', event.target.value)}
        />
      )}
      {
        <TextField
          multiline
          variant='outlined'
          className='title-text-field margin-text-field'
          value={_get(cloneSource, 'TEXT', '')}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => handleOnSourceChanges('TEXT', event.target.value)}
        />
      }
    </>
  );

  return (
    <div className='death-notice-details'>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Grid container>
            {isEdit ? _renderDeathNoteEditContent() : _renderDeathNoteContent()}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loading ? <Skeleton width={200} height={50} variant="text" /> : editPermitted &&
                (isEdit === false ? (
                  <Button
                    className={classes['edit-button']}
                    variant='contained'
                    color='primary'
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Edit this story
                  </Button>
                ) : (
                  <ButtonGroup
                    className={classes['edit-button']}
                    variant='contained'
                    color='primary'
                    aria-label='outlined primary button group'
                  >
                    <Button onClick={() => setIsEdit(!isEdit)}>
                      Back to preview mode
                    </Button>
                    <Button
                      onClick={() => {
                        onClickSubmitUpdateDocument(cloneSource);
                      }}
                    >
                      Update
                    </Button>
                  </ButtonGroup>
                ))}
            </Grid>
            <Grid item xs={12}>
              <DetailsMetaInfo
                loading={loading}
                {...metaInfo}
                isEdit={isEdit}
                handleOnSourceChanges={handleOnSourceChanges}
                cloneSource={cloneSource}
              />
            </Grid>
            <Grid item xs={12}>
              <ShareBox loading={loading}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeathNoticeDetails;
