import {useAuth0Context} from '../context/auth';

export const useRoles = (role?: string): boolean => {
  if (role === undefined || role === null) {
    throw new Error('A string role must be defined in your environment');
  }

  const {authState} = useAuth0Context();
  const roles = authState?.roles || [];

  return roles.includes(role);
};

export default useRoles;
