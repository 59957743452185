import React, {useState, useCallback} from 'react';

export interface SearchQueryState {
  query: string,
}

export interface SearchQueryProvider {
  data: SearchQueryState,
  rerender: any,
  updateQuery: (query: string) => void,
  forceUpdate: () => void,
}

export const SearchQueryContext = React.createContext<SearchQueryProvider>({
  data: {query: ''},
  rerender: {},
  updateQuery: () => {},
  forceUpdate: () => {},
});

const Provider: React.FC = ({children}) => {
  const [state, setState] = useState<SearchQueryState>({
    query: '',
  });
  const [renderState, rerender] = useState<any>();

  return (
   <SearchQueryContext.Provider
      value={{
        data: state,
        rerender: renderState,
        updateQuery: (query: string) => {
          setState({ ...state, query });
        },
        forceUpdate: useCallback(() => rerender({}), []),
      }}
    >
      {children}
    </SearchQueryContext.Provider>
  );
};

export default Provider;