import { Dispatch, SetStateAction } from "react";

export const handleLoadingStateChange = async (
  setter: Dispatch<SetStateAction<boolean>>,
  closure: () => Promise<any>,
  exception?: (err: any) => Promise<any>,
) => {
  setter(true);
  try {
    await closure();
  } catch (err) {
    exception && await exception(err);
  } finally {
    setter(false);
  }
};

export default handleLoadingStateChange;
