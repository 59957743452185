import { useState, useEffect } from "react";

const areKeysPressed = (keys: string[], keysPressed: Set<string>) => {
  const required = new Set(keys);
  keysPressed.forEach(elem => {
    required.delete(elem);
  })
  return required.size === 0;
}

export const useMultiKeyPress = (...keys: string[]) => {
  const [keysPressed, setKeyPressed] = useState(new Set<string>([]));

  const downHandler = ({key}: {key: string}) => {
    setKeyPressed(new Set(keysPressed.add(key)));
  }

  const upHandler = ({key}: {key: string}) => {
    keysPressed.delete(key);
    setKeyPressed(new Set(keysPressed));
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  return areKeysPressed(keys, keysPressed);
};

export default useMultiKeyPress;
