import env from '../context/environment/initial';
import axios from 'axios';

export interface ShareDocumentOptions {
  bearerToken: string,
  sendTo: string,
  recipient: string,
  note: string,
}

const esDocumentEndpoint = `${env.REACT_APP_ELASTIC_SEARCH_ENDPOINT}/document`;

export const shareDocument = <T> (id: string, {
  bearerToken,
  sendTo,
  recipient,
  note,
}: ShareDocumentOptions) => axios.post<T>(
  `${esDocumentEndpoint}/${id}`,
  {
    action: sendTo,
    recipient,
    note,
  },
  {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  },
);

export default shareDocument;
